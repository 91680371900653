import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ModelContext } from '../context/ModelContext'
import toast, { Toaster } from 'react-hot-toast'
import LightboxComponent from '../components/Lighbox'
import Modal from '../components/ModalCintoo'
import Chatbox from '../components/Chatbox'
import Insight from '../components/Insight'
import { ClipLoader } from 'react-spinners'
import planosButton from '../img/pdf-button.png'
import revitButton from '../img/revit-button.png'
import pointcloud from '../img/point.png'
import PanellumExample from '../components/Panellum'
import ViewerPage from '../components/ViewerPage'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import {
    GoogleMap,
    Marker,
    withScriptjs,
    withGoogleMap,
} from 'react-google-maps'
import PlaceDetails from '../components/PlaceDetails'

const GoogleMapComponent = withScriptjs(
    withGoogleMap(({ lat, lng }) => (
        <GoogleMap defaultZoom={14} defaultCenter={{ lat, lng }}>
            <Marker position={{ lat, lng }} />
        </GoogleMap>
    ))
)

const Projectnotmemo = () => {
    const { id } = useParams()
    const [cookies, _] = useCookies(['access_token'])
    const { setData, selectedModel, selectionProps, forgeToken, nodeList } =
        useContext(ModelContext)
    const [numeroVersion, setNumeroVersion] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)
    const [versionesAnterioresArray, setVersionesAnterioresArray] = useState([])
    const [urn, setUrn] = useState(null)
    const [forgeAccessToken, setForgeAccessToken] = useState(null)
    const [insightSide, setInsightSide] = useState([])
    const [bottonSide, setBottonSide] = useState([])
    const [loadingModel, setLoadingModel] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [iframeSrc, setIframeSrc] = useState(null)
    const [passCinto, setPassCinto] = useState(null)
    const [pdfList, setPdfList] = useState({})
    const [pdfModalOpen, setPdfModal] = useState(false)
    const [selectedView, setSelectedView] = useState(0)

    const getSelectedElementProperties = () => {
        const selectedProperties = []

        if (selectionProps && selectionProps.length >= 1) {
            for (const prop1 of selectionProps) {
                for (const prop of prop1) {
                    const elementProperties = {}

                    // Agregar ID en lugar de dbId
                    elementProperties.ID = prop.dbId

                    // Ahora iteramos sobre el array 'properties' dentro de 'prop'
                    for (const property of prop.properties) {
                        let { displayName, displayValue, units } = property

                        if (units) {
                            // Verificar que 'units' no sea undefined antes de dividirlo
                            if (typeof units === 'string') {
                                // Dividir el valor de las unidades por ':' para obtener las partes
                                const unitParts = units.split(':')
                                if (unitParts && unitParts.length >= 2) {
                                    const unitAbbreviation =
                                        unitParts[1].split('-')[0]
                                    displayValue = `${displayValue.toFixed(
                                        3
                                    )} ${unitAbbreviation}`
                                }
                            }
                        }

                        switch (displayName) {
                            case 'Category':
                                elementProperties.Category = displayValue
                                break
                            case 'Function':
                                elementProperties.Function = displayValue
                                break
                            case 'Length':
                                elementProperties.Length = displayValue
                                break
                            case 'Type Name':
                                elementProperties.Material = displayValue
                                break
                            case 'Volume':
                                elementProperties.Volume = displayValue
                                break
                            case 'Unconnected Height':
                                elementProperties.Height = displayValue
                                break
                            case 'Width':
                                elementProperties.Width = displayValue
                                break
                            case 'instructions':
                                elementProperties.instructions = displayValue
                                break
                            case 'Area':
                                elementProperties.Area = displayValue
                                break
                            case 'Comments':
                                elementProperties.ProblemsorComments =
                                    displayValue
                                break
                            case 'Estado':
                            case 'State':
                                elementProperties.StateofTheElement =
                                    displayValue
                                break
                            case 'Thickness':
                                elementProperties.Thickness = displayValue
                                break
                            default:
                                // Omitir otras propiedades
                                break
                        }
                    }

                    // Solo añadimos elementProperties al array si tiene al menos una propiedad
                    if (Object.keys(elementProperties).length > 0) {
                        selectedProperties.push(elementProperties)
                    }
                }
            }
        }
        console.log(selectedProperties)
        return selectedProperties
    }

    const handlePdfDownload = async (key) => {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/forge/getDownloadPdf`,
            {
                accessToken: forgeToken,
                urn: selectedOption,
                derivative: pdfList[key],
            },
            {
                headers: {
                    authorization: cookies.access_token,
                },
            }
        )
        window.open(response.data.downloadUrl)
    }

    const handleModalClose = () => {
        setPdfModal(false)
    }

    const handleViewChange = (event) => {
        setSelectedView(event.target.value)
    }

    // Función para abrir el modal
    const openModal = () => {
        setIsModalOpen(true)
        setIframeSrc(selectedModel.cintooURL)
    }
    // Función para cerrar el modal
    const closeModal = () => {
        setIsModalOpen(false)
    }

    // Función para obtener el número de versión de la URN
    function obtenerNumeroVersion(urn) {
        const versionIndex = urn.lastIndexOf('version=')
        if (versionIndex !== -1) {
            return urn.substring(versionIndex + 8)
        }
        return null
    }

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value
        setSelectedOption(selectedValue)
        console.log('Valor seleccionado:', selectedValue)
        // ENVIAR LA VERSION AL VISOR
    }

    useEffect(() => {
        if (urn) {
            const versionActual = obtenerNumeroVersion(urn)
            console.log(versionActual)
            // Generar lista de versiones anteriores a partir de la versión 2
            const versionesAnteriores = []
            for (let i = 2; i < versionActual; i++) {
                versionesAnteriores.push(i)
            }
            console.log(versionesAnteriores)
            // Actualizar el estado con el número de versión y las versiones anteriores
            console.log(versionActual)
            setNumeroVersion(versionActual)
            setVersionesAnterioresArray(versionesAnteriores)
            // Seleccionar por defecto la última versión al cargar la página
            setSelectedOption(urn)
        }
    }, [urn])

    useEffect(() => {
        const fetchModel = async () => {
            // Concatena la URL base con la ruta específica
            const modelUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/models/model/${id}`
            try {
                const response = await axios.get(modelUrl, {
                    headers: { authorization: cookies.access_token },
                })
                setData(response.data, 'selectedModel')
            } catch (err) {
                console.log(err)
            }
        }
        fetchModel()
    }, [])
    useEffect(() => {
        // Verificar si selectedModel tiene datos
        if (selectedModel) {
            toast.success('Uploaded successful')
            setPassCinto(selectedModel.CintoP)
            // Obtener el número de versión de la URN actual
            const components = {
                insight: (
                    <div className="flex-1 md:mr-2 ">
                        <Insight value={selectedModel} />
                    </div>
                ),
                panellum: (
                    <div className="flex-1 md:mr-2 ">
                        <PanellumExample />
                    </div>
                ),
                googleMaps: (
                    <div className="flex-1 md:mr-2 ">
                        <PlaceDetails value={selectedModel} />
                    </div>
                ),
                galleryImage: (
                    <div className="flex-1 md:mr-2 ">
                        <LightboxComponent />
                    </div>
                ),
            }
            setBottonSide([])
            setInsightSide([])
            const layout = selectedModel.layout
            layout.bottonSide.map((key) => {
                setBottonSide((prev) => {
                    return [...prev, components[key]]
                })
            })
            layout.insightSide.map((key) => {
                setInsightSide((prev) => {
                    return [...prev, components[key]]
                })
            })
        }
    }, [selectedModel])

    const api = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    })

    useEffect(() => {
        const getToken = async () => {
            try {
                const response = await api.get(`/forge/token`, {
                    headers: { authorization: cookies.access_token },
                })
                setForgeAccessToken(response.data.access_token)
            } catch (err) {
                console.log(err)
            }
        }

        getToken()
    }, [cookies])

    useEffect(() => {
        const getVersionUrn = async () => {
            const response = await api.post(
                `/forge/getTip`,
                {
                    projectId: 'b.' + selectedModel.projectID,
                    accessToken: forgeAccessToken,
                    urn: selectedModel.urn,
                },
                {
                    headers: { authorization: cookies.access_token },
                }
            )
            setUrn(response.data)
            setLoadingModel(false)
        }
        if (selectedModel && forgeAccessToken) {
            getVersionUrn()
        }
    }, [selectedModel, forgeAccessToken])

    //   useEffect(() => {
    //     if(urn){
    //     // Obtener la parte de la cadena antes de "?version="
    //     console.log(urn)
    //     const partes = urn.split("?version=");
    //     const parteAntesDeVersion = partes[0];

    //     // Construir la nueva cadena con el valor actual de version
    //    let urnNueva = parteAntesDeVersion + "?version=" + selectedOption;
    //    console.log(urnNueva)
    //     setUrn(urnNueva)
    // }
    //     // Imprimir la nueva urn
    //   }, [ selectedOption]); // El useEffect se ejecutará version cambie

    return (
        <>
            <Toaster />

            <div id="container-project" className="transition-all">
                {loadingModel ? (
                    <div className="fixed inset-0 flex justify-center items-center bg-gradient-to-bl from-blue-900 to-purple-900 dark:bg-gray-800 z-50">
                        <ClipLoader
                            color="#ffffff"
                            loading={loadingModel}
                            size={50}
                        />
                    </div>
                ) : (
                    <>
                        {selectedModel ? (
                            <div className="rounded-lg p-4 md:p-6 lg:pt-2 lg:pb-2 my-2 md:my-4 mx-4 sm:mx-6 md:mx-10 lg:mx-0 flex flex-col md:flex-row items-start md:items-center">
                                {/* Parte izquierda en pantallas grandes */}
                                <div className="lg:w-1/2 mb-4 md:mb-0">
                                    <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 dark:text-gray-300 mb-2">
                                        {selectedModel.name}
                                    </h2>
                                    <a
                                        href={`https://www.google.com/maps/search/?api=1&query=${selectedModel.address.description}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-md md:text-lg text-gray-500 dark:text-gray-400 font-medium"
                                    >
                                        {selectedModel.address.description}
                                    </a>
                                </div>

                                {/* Resto a la derecha */}
                                <div className=" flex flex-col md:flex-row items-center mt-4 md:mt-0  md:space-y-0 md:space-x-4 w-full md:w-auto">
                                    {/* Chatbox */}
                                    <div
                                        style={{
                                            position: 'relative',
                                            zIndex: 9999,
                                        }}
                                    >
                                        <div className="fixed bottom-5 right-5 rounded-full cursor-pointer">
                                            {' '}
                                            <Chatbox
                                                propsFROMforge={getSelectedElementProperties()}
                                            />
                                        </div>
                                    </div>

                                    {/* Select para la vista */}
                                    <select
  id="viewSelect"
  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full md:w-auto"
  onChange={handleViewChange}
  value={selectedView}
>
  {nodeList.length === 0 ? (
    <option value="" disabled selected>
      Loading...
    </option>
  ) : (
    <>
      <option value="" disabled>
        Select
      </option>
      {nodeList.map((node, i) => (
        <option key={i} value={i}>
          {node.data.name}
        </option>
      ))}
    </>
  )}
</select>


                                    {/* Botón y modal para Point Cloud */}
                                    {selectedModel.cintooURL &&
                                    selectedModel.cintooURL.trim() !== '' ? (
                                        <div
                                            className="text-center cursor-pointer mb-2 lg:mr-6"
                                            onClick={openModal}
                                        >
                                            <img
                                                src={pointcloud}
                                                className="w-26 h-16"
                                                alt="Point Cloud"
                                            ></img>
                                            <p className="text-cyan-950 font-bold dark:text-gray-200">
                                                POINT CLOUD
                                            </p>
                                            <Modal
                                                isOpen={isModalOpen}
                                                onClose={closeModal}
                                                iframeSrc={iframeSrc}
                                                passCinto={passCinto}
                                            />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {/* Botones para descargar */}
                                    <div className="flex flex-row items-center">
                                        <div
                                            className="cursor-pointer"
                                            onClick={async () => {
                                                const response =
                                                    await axios.post(
                                                        `${process.env.REACT_APP_BACKEND_BASE_URL}/forge/getDownloadLink`,
                                                        {
                                                            projectId:
                                                                selectedModel.projectID,
                                                            accessToken:
                                                                forgeToken,
                                                            urn: selectedOption,
                                                        },
                                                        {
                                                            headers: {
                                                                authorization:
                                                                    cookies.access_token,
                                                            },
                                                        }
                                                    )
                                                window.open(response.data.url)
                                            }}
                                        >
                                            {' '}
                                            <img
                                                src={revitButton}
                                                className="w-16 h-16"
                                                alt="Revit Button"
                                            />
                                        </div>
                                        <div>
                                            <div
                                                className="cursor-pointer"
                                                onClick={async () => {
                                                    const response =
                                                        await axios.post(
                                                            `${process.env.REACT_APP_BACKEND_BASE_URL}/forge/getManifest`,
                                                            {
                                                                accessToken:
                                                                    forgeToken,
                                                                urn: selectedOption,
                                                            },
                                                            {
                                                                headers: {
                                                                    authorization:
                                                                        cookies.access_token,
                                                                },
                                                            }
                                                        )

                                                    const names = {}
                                                    response.data.map(
                                                        (item) => {
                                                            const name =
                                                                item.urn
                                                                    .split('/')
                                                                    .slice(
                                                                        -1
                                                                    )[0]
                                                            names[name] = item
                                                        }
                                                    )
                                                    setPdfList(names)
                                                    setPdfModal(true)
                                                }}
                                            >
                                                <img
                                                    src={planosButton}
                                                    className="w-16 h-16 mr-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Select de versión */}
                                <div className="flex flex-row items-center w-full md:w-auto">
                                    <select
                                        id="version-select"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full md:w-auto"
                                        onChange={handleSelectChange}
                                        value={selectedOption}
                                    >
                                        <option value="" disabled>
                                            Select
                                        </option>
                                        {versionesAnterioresArray.map(
                                            (version) => {
                                                const urnVersion = urn.replace(
                                                    /version=\d+/,
                                                    `version=${version}`
                                                )
                                                return (
                                                    <option
                                                        key={version}
                                                        value={urnVersion}
                                                    >
                                                        V{version}
                                                    </option>
                                                )
                                            }
                                        )}
                                        <option key={numeroVersion} value={urn}>
                                            V{numeroVersion}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="transition-all">
                            <ViewerPage
                                urn={selectedOption}
                                selectedIndex={selectedView}
                            />
                        </div>
                        <div
                            id="defaultModal"
                            tabindex="-1"
                            aria-modal="true"
                            role="dialog"
                            class={`fixed bg-gray-800/25 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex ${
                                pdfModalOpen ? '' : 'hidden'
                            }`}
                            onKeyUp={(e) => {
                                if (e.key === 'Escape') {
                                    handleModalClose()
                                }
                            }}
                            onClick={(e) => {
                                if (e.target.id === 'defaultModal') {
                                    handleModalClose()
                                }
                            }}
                        >
                            <div class="relative w-full max-w-2xl max-h-full">
                                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                            Selecciona archivo a descargar
                                        </h3>
                                        <button
                                            type="button"
                                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            onClick={() => {
                                                handleModalClose()
                                            }}
                                        >
                                            <svg
                                                class="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="p-6  flex justify-evenly flex-wrap">
                                        {Object.keys(pdfList).map((item) => {
                                            return (
                                                <div
                                                    className="text-center text-blue-400 mx-2 min-w-[200px] cursor-pointer"
                                                    onClick={() => {
                                                        handlePdfDownload(
                                                            item
                                                        ).then((result) => {
                                                            console.log(result)
                                                        })
                                                    }}
                                                >
                                                    {item}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row   rounded-lg p-1  my-2 md:my-4 mx-2 sm:mx-4 md:mx-8 mb-1 overflow-hidden">
                            {insightSide}
                        </div>

                        <div className="flex flex-col md:flex-row   rounded-lg p-1  my-2 md:my-4 mx-2 sm:mx-4 md:mx-8 mb-1 overflow-hidden">
                            {bottonSide}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

const Project = React.memo(Projectnotmemo)

export default Project
