import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Card,
    Flex,
    Metric,
    ProgressCircle,
} from '@tremor/react'


const EdilicialState = ({ data, category }) => {
    const { t } = useTranslation()

    // Obtener la fecha actual
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // Los meses en JavaScript son 0-indexados

    // Inicializar variables para el promedio
    let edilicialStateSum = 0
    let matchingTiles = []

    if (category.length === 0) {
        // Si no se especifica una categoría, incluir todas las tiles
        matchingTiles = data.filter(
            (tile) => tile.tile[currentYear]?.[currentMonth]
        )
    } else {
        // Si se especifica una categoría, incluir solo las tiles que coincidan con la categoría
        console.log('la categoria es ' + category)
        console.log(data)
        matchingTiles = data.filter(
            (tile) =>
                tile.tile[currentYear]?.[currentMonth] &&
                category.includes(tile.category)
        )
    }

    // Calcular la suma de los estados generales
    matchingTiles.forEach((tile) => {
        const tileData = tile.tile[currentYear][currentMonth]
        edilicialStateSum += tileData.EdilicialState
    })

    // Calcular el promedio dividiendo la suma total por el número de coincidencias
    const numberOfMatches = matchingTiles.length
    const generalStateEdilicialAverage =
        numberOfMatches > 0 ? edilicialStateSum / numberOfMatches : 0
    console.log(
        'El resultado final es ' +
        generalStateEdilicialAverage +
            ' de ' +
            numberOfMatches +
            ' modelos'
    )
    // console.log(data)
    return (
        <div className="rounded-xl bg-gradient-to-r p-1 from-[#6EE7B7] via-[#3B82F6] to-[#9333EA] m-1 w-full dark:shadow-2xl	dark:shadow-zinc-700">

        <Card className=" ">
                            <div className="flex flex-col text-center	 justify-between">

        <Flex className="mb-3" >
                  <Metric> {t('edilicialText')} </Metric>
              </Flex>
  
              <ProgressCircle
                  value={(generalStateEdilicialAverage * 100).toFixed(0)}
                  size="md"
                  strokeWidth="9"
                  className="mb-3"
                  color="indigo" 
              >
                  <span className="dark:text-white font-medium">
                      {(generalStateEdilicialAverage * 100).toFixed(0)}
                  </span>
              </ProgressCircle>
  </div>
          </Card>
          </div>
    )
}

export default EdilicialState
