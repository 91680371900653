import React, { useState } from 'react';
import axios from 'axios';

const CreateCategories = ({ formDataPage2, setFormDataPage2, cookies, company }) => {
  const [newCategory, setNewCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [error, setError] = useState('');
  const handleAddCategory = () => {
    if (newCategory.trim() === '') return;

    // Agregar la nueva categoría a la lista de categorías en el estado local
    setCategoryList([...categoryList, newCategory]);

    // Limpiar el campo de entrada de texto
    setNewCategory('');
  };

  const handleCreateCategories = async () => {
    try {
      // Crear un array con los datos de las nuevas categorías
      const categoriesData = categoryList.map((category) => ({
        nameZone: category,
        company: company,
      }));

      // Realizar una solicitud POST por cada elemento en categoriesData
      for (const categoryData of categoriesData) {
        console.log(categoryData)

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/zone/createZone`,
          categoryData,
          {
            headers: {
              'Content-Type': 'application/json',
              authorization: cookies.access_token,
            },
          }
        );
        console.log('Nueva categoría creada en el backend:', response.data);
      }

      // Actualizar el estado global de formDataPage2
      const newCategoryNames = categoryList.map((category) => category);
      setFormDataPage2({
        ...formDataPage2,
        category: [...formDataPage2.category, ...newCategoryNames],
      });

      // Limpiar la lista de categorías en el estado local
      setCategoryList([]);

      // Limpiar cualquier error anterior
      setError('');
    } catch (err) {
      console.error('Error al crear las categorías:', err);
      setError('Error al crear las categorías. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className=" text-white flex justify-center items-center">
      <form onSubmit={(e) => e.preventDefault()} className="space-y-4 p-4 bg-gray-800 rounded-lg">
        <div className="flex flex-col items-center">
          <label className="font-semibold text-lg">Categorías:</label>
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Nueva categoría"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              className=" w-full rounded-l border-0 p-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <button
              type="button"
              onClick={handleAddCategory}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r"
            >
              +
            </button>
          </div>
          <div className="mt-2">
            {categoryList.map((category, index) => (
              <div key={index} className="flex items-center space-x-2">
                <span>{category}</span>
              </div>
            ))}
          </div>
        </div>
        <button
          type="button"
          onClick={handleCreateCategories}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Crear Categorías
        </button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </div>
  );
};

export default CreateCategories;
