import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'

export const RegisterUser = () => {
    // Definir estados para almacenar datos del formulario
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [company, setCompanyId] = useState('') // Para guardar la ID de la compañía seleccionada
    const [companies, setCompanies] = useState([]) // Para almacenar la lista de compañías disponibles
    const [cookies, _] = useCookies(['access_token'])

    // Obtener la lista de compañías disponibles al cargar el componente
    useEffect(() => {
        // Función asincrónica para obtener compañías desde el backend
        async function fetchCompanies() {
            try {
                // Realizar una solicitud GET al backend para obtener las compañías
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/companies/getCompanies`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )
                // Establecer la lista de compañías en el estado
                setCompanies(response.data)
            } catch (error) {
                console.error(error)
            }
        }

        // Llamar a la función para obtener las compañías al cargar el componente
        fetchCompanies()
    }, [])

    // Función para manejar el envío del formulario
    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            // Enviar los datos del formulario, incluyendo la ID de la compañía seleccionada
            await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/register`,
                {
                    username,
                    password,
                    company, // Enviar la ID de la compañía seleccionada
                }
            )
            // Mostrar una alerta de registro exitoso
            alert('Registration Completed! Now login.')
        } catch (error) {
            console.error(error)
        }
    }

    // Renderizar el formulario y los campos de entrada
    return (
        <div className="flex items-center justify-center">
            <div className="w-full max-w-lg mt-40">
                <form
                    className="rounded px-8 pt-6 pb-8 mb-4"
                    onSubmit={handleSubmit}
                >
                    <h2 className="text-2xl font-bold mb-4">Register</h2>

                    {/* Campo de entrada para el nombre de usuario */}
                    <div className="mb-4">
                        <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="username"
                        >
                            Username:
                        </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(event) =>
                                setUsername(event.target.value)
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    {/* Campo de entrada para la contraseña */}
                    <div className="mb-4">
                        <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="password"
                        >
                            Password:
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    {/* Selección de la compañía */}
                    <div className="mb-4">
                        <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="companyId"
                        >
                            Company:
                        </label>
                        <select
    id="company"
    value={company}
    onChange={(event) => setCompanyId(event.target.value)}
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
>
    <option value="">Select a company</option>
    {/* Mapear y mostrar las opciones de compañía disponibles */}
    {companies.map((company) => (
        <option key={company._id} value={company._id}>
            {company.nameCompany}
        </option>
    ))}
</select>
                    </div>

                    {/* Botón para enviar el formulario */}
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
                    >
                        Register
                    </button>
                </form>
            </div>
        </div>
    )
}
