import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Flex, Metric,Title, CategoryBar, BadgeDelta } from '@tremor/react'
import GeneralStateChart from './GeneralStateChart '

const GeneralState = ({ data, category }) => {
    const { t } = useTranslation()
    console.log(data)
    // Obtener la fecha actual
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // Los meses en JavaScript son 0-indexados

    // Inicializar variables para el promedio
    let generalStateSum = 0
    let generalVariantStateSum = 0;
    let variantCounter = 0
    let matchingTiles = []
    if (category.length === 0) {
        // Si no se especifica una categoría, incluir todas las tiles
        matchingTiles = data.filter(
            (tile) => tile.tile[currentYear]?.[currentMonth]
        )
    } else {
        // Si se especifica una categoría, incluir solo las tiles que coincidan con la categoría
        console.log('la categoria es ' + category)
        console.log("la data es "  )
        console.log(data)

        matchingTiles = data.filter(
            (tile) =>
                tile.tile[currentYear]?.[currentMonth] &&
                category.includes(tile.category)
        )
        console.log("El modelo que coincide es : ")
        console.log(matchingTiles)
    }

    // Calcular la suma de los estados generales
    matchingTiles.forEach((tile) => {
        const tileData = tile.tile[currentYear][currentMonth]
        generalStateSum += tileData.generalState
    
        // Asegurarse de que tile.variantState esté definido y sea un número antes de sumarlo
        if (typeof tile.variantState === 'number') {
            generalVariantStateSum += tile.variantState
            variantCounter++

        }

    })

    // Calcular el promedio dividiendo la suma total por el número de coincidencias
    const numberOfMatches = matchingTiles.length
    const generalStateAverage =
        numberOfMatches > 0 ? generalStateSum / numberOfMatches : 0
    const generalStateVariantAverage =
    variantCounter > 0 ? generalVariantStateSum / variantCounter : 0
    console.log(generalStateVariantAverage)

    console.log(
        'El resultado final es ' +
        generalStateAverage +
            ' de ' +
            variantCounter +
            ' modelos'
    )


    // Función para determinar el color y el texto según el valor de variantState
    const getBadgeDelta = (variantState) => {
        let deltaType = 'unchanged'
        let text = 'Unchanged'

        if (typeof variantState !== 'undefined') {
            if (variantState > 5) {
                deltaType = 'increase'
                text = 'Increase'
            } else if (variantState > 0) {
                deltaType = 'moderateIncrease'
                text = 'Moderate Increase'
            } else if (variantState < -5) {
                deltaType = 'decrease'
                text = 'Decrease'
            } else if (variantState < 0) {
                deltaType = 'moderateDecrease'
                text = 'Moderate Decrease'
            }
        }

        return (
            <BadgeDelta deltaType={deltaType}>
                {/* {text}{' '} */}
                {typeof generalStateVariantAverage !== 'undefined'
                    ? `(${generalStateVariantAverage.toFixed(2)}%)`
                    : ''}
            </BadgeDelta>
        )
    }


    // console.log(data)
    return (
        <div className="rounded-xl bg-gradient-to-r p-1 from-[#6EE7B7] via-[#3B82F6] to-[#9333EA] m-1 shadow-2xl dark:shadow-zinc-700	w-full	">

        <Card className="h-full">
            <Flex className="" justifyContent="between" alignItems="center">
                <Metric className=''> {t('SecondTileText')} </Metric>
                {data && (
                <div className="">{getBadgeDelta(generalStateVariantAverage)}</div>
            )}
            </Flex>


            <CategoryBar
      values={[20, 30, 25, 15,10]}
      colors={["rose", "orange", "yellow", "emerald","blue"]}
      markerValue={(generalStateAverage * 100).toFixed(0)}
      className="mt-4 "
    />

  <Title className='mt-4'> {t('PromGeneralState')}{(generalStateAverage * 100).toFixed(0)} %</Title>
        
            {/* <GeneralStateChart data={data} /> */}
        </Card>
        </div>
    )
}

export default GeneralState
