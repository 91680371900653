import React, { useContext, useState, useEffect } from 'react';
import { ModelContext } from '../context/ModelContext';

export default function PanellumExample() {
  const { setData, selectedModel } = useContext(ModelContext);
  const [iframeURL, setIframeURL] = useState('');

  useEffect(() => {
    // Verificar si selectedModel tiene datos antes de proceder
    if (selectedModel && selectedModel.panellum) {
      // Aquí cargamos la configuración del panel Panellum
      const configId = selectedModel.panellum;

      const configURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/json/${configId}`;


      // Crear la URL completa para el iframe
      const newIframeURL = `https://cdn.pannellum.org/2.5/pannellum.htm#config=${configURL}`;
        console.log(newIframeURL)    
        // Actualizamos el estado con la nueva URL del iframe
      setIframeURL(newIframeURL);
    }
  }, [selectedModel && selectedModel.panellum]); // Se ejecutará cuando selectedModel.panellum cambie

  // Si selectedModel no tiene datos, retorna null (no renderiza nada)
  if (!selectedModel || !selectedModel.panellum) return null;

  return (
    <div className="w-full overflow-hidden" style={{ height: '60vh' }}> {/* Cambio en esta línea */}
    <div className="aspect-w-16 aspect-h-9" style={{ height: '100%' }}> {/* Puedes añadir altura aquí también si lo necesitas */}
      <iframe
        className="w-full h-full"
        allowFullScreen
        style={{ borderStyle: "none" }}
        src={iframeURL}
      >
      </iframe>
    </div>
  </div>
  );
}
