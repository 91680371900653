import React from 'react'
import { useTranslation } from 'react-i18next'

const Search = ({ searchTerm, onSearchChange }) => {
    const { t } = useTranslation()

    return (
        <div className=" rounded-full mb-2 ">
            <form>
                <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 dark:text-white sr-only"
                >
                    Search
                </label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                
                            {/* ... Ícono de búsqueda ... */}
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        className="block w-full p-4 pl-6 text-sm text-gray-900 dark:bg-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-grey-500   
                        dark:border-gray-800 dark:placeholder-gray-400 dark:text-white"
                        placeholder= {t('searchModel')}
                        required
                        value={searchTerm} // Agrega el valor del estado de búsqueda
                        onChange={onSearchChange} // Agrega la función de cambio
                    />
                    <button
                        type="submit"
                        className="text-white dark:bg-[#493E98] absolute right-2.5 bottom-2.5 bg-[#493E98] hover:bg-[#6c8088] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:text-gray-200 dark:hover:bg-grey-700"
                    >
                        {t('search')}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Search
