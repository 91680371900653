import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { useGetCompanyID } from '../hooks/useGetCompanyID'
import {
    Flex,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Badge,
    CategoryBar,
    BadgeDelta,
} from '@tremor/react'
import { ClipLoader } from 'react-spinners'
import NAVflotante from '../components/NAVflotante'
import ComparationTab from '../components/comparation/comparationTab'
import ZoneTab from '../components/comparation/zoneTab'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Comparation = () => {
    const [models, setModels] = useState([])
    const [numberOfModels, setNumberOfModels] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [cookies, _] = useCookies(['access_token'])
    const companyID = useGetCompanyID()
    const [dataLoaded, setDataLoaded] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/models/${companyID}`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )

                // Filtrar modelos según el término de búsqueda
                const filteredModels = response.data.filter((model) => {
                    return model.name.toLowerCase()
                })

                // Agregar la URL de la imagen a cada modelo
                const modelsWithImageURLs = await Promise.all(
                    filteredModels.map(async (model) => {
                        const imageResponse = await axios.get(
                            `${process.env.REACT_APP_BACKEND_BASE_URL}/s3/get-image-url`,
                            {
                                params: {
                                    folderName: 'thumbnails',
                                    originalName: model.thumbnail,
                                },
                            }
                        )

                        return {
                            ...model,
                            imageURL: imageResponse.data.url,
                        }
                    })
                )

                // Actualizar el estado con los modelos filtrados y con URLs de imágenes
                setModels(modelsWithImageURLs)
                setNumberOfModels(modelsWithImageURLs.length)
                setIsLoading(false) // Establecer isLoading en false cuando se complete la carga
            } catch (error) {
                console.error('Error al obtener y filtrar modelos:', error)
                setIsLoading(false) // En caso de error, también establecer isLoading en false
            }
        }

        if (!dataLoaded) {
            fetchData() // Llamar a la función fetchData al montar el componente
            setDataLoaded(true)
        }
    }, [companyID, dataLoaded])

    // Función para ordenar la tabla por un campo específico
    const sortByField = (field) => {
        const sortedModels = [...models].sort((a, b) => {
            if (a[field] < b[field]) return -1
            if (a[field] > b[field]) return 1
            return 0
        })
        setModels(sortedModels)
    }

    // Función para determinar el color y el texto según el valor de variantState
    const getBadgeDelta = (variantState) => {
        console.log(variantState)
        let deltaType = 'unchanged'
        let text = 'Unchanged'

        if (typeof variantState !== 'undefined') {
            if (variantState > 5) {
                deltaType = 'increase'
                text = 'Increase'
            } else if (variantState > 0) {
                deltaType = 'moderateIncrease'
                text = 'Moderate Increase'
            } else if (variantState < -5) {
                deltaType = 'decrease'
                text = 'Decrease'
            } else if (variantState < 0) {
                deltaType = 'moderateDecrease'
                text = 'Moderate Decrease'
            }
        }

        return (
            <BadgeDelta deltaType={deltaType}>
                {typeof variantState !== 'undefined'
                    ? `(${variantState.toFixed(2)}%)`
                    : ''}
            </BadgeDelta>
        )
    }

    return (
        <div className="min-h-screen overflow-hidden flex space-x-2">
            <div className="hidden md:block w-28">
                <div className="bg-gray-50 dark:bg-slate-900">
                    <NAVflotante />
                </div>
            </div>
            <div className="w-full">
                <div>
                    <TabGroup>
                        <TabList
                            color="indigo"
                            className="mt-8 flex justify-center dark:bg-gray-800"
                        >
                            <Tab className="px-4 py-2 cursor-pointer text-black dark:text-white">
                                GENERAL
                            </Tab>
                            <Tab className="px-4 py-2 cursor-pointer text-black dark:text-white">
                                {t('comparation')}
                            </Tab>
                            <Tab className="px-4 py-2 cursor-pointer text-black dark:text-white">
                                {t('zones')}
                            </Tab>
                        </TabList>

                        <TabPanels className="w-fit mx-auto">
                            <TabPanel>
                                {isLoading ? (
                                    <div className="flex flex-col justify-center items-center mt-16">
                                        <ClipLoader
                                            color="#4B5A65"
                                            loading={isLoading}
                                            size={50}
                                        />
                                    </div>
                                ) : (
                                    <div className="mt-10">
                                        <Flex className="mt-4">
                                            <table className="table-fixed bg-white dark:bg-gray-800 text-black dark:text-white rounded-lg shadow-lg md:shadow-day dark:shadow-sky-700">
                                                <thead>
                                                    <tr className="bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 text-left">
                                                        <th></th>
                                                        <th
                                                            className="px-4 py-2 cursor-pointer"
                                                            onClick={() =>
                                                                sortByField(
                                                                    'name'
                                                                )
                                                            }
                                                        >
                                                            {t('names')}
                                                        </th>
                                                        <th className="px-4 py-2">
                                                            {t('variation')}
                                                        </th>
                                                        <th className="px-4 py-2">
                                                            {t('state')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {models.map((model) => (
                                                        <tr
                                                            key={model._id}
                                                            className={`hover:bg-gray-100 dark:hover:bg-gray-900 transition duration-300 ${
                                                                model.actualState *
                                                                    100 <
                                                                60
                                                                    ? 'bg-red-900'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <td>
                                                                <img
                                                                    src={
                                                                        model.imageURL
                                                                    }
                                                                    className="w-20"
                                                                    alt={
                                                                        model.name
                                                                    }
                                                                ></img>
                                                            </td>
                                                            <td className="px-4 py-2 text-sm">
                                                                <Link
                                                                    to={`/project/${model._id}`}
                                                                    key={
                                                                        model._id
                                                                    }
                                                                    className="text-blue-500 dark:text-blue-300"
                                                                >
                                                                    {model.name}
                                                                </Link>
                                                            </td>
                                                            <td className="px-4 py-2">
                                                                {model && (
                                                                    <div className="mt-1">
                                                                        {getBadgeDelta(
                                                                            model.variantState
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td className="px-4 py-2 w-48">
                                                                <CategoryBar
                                                                    values={[
                                                                        40, 30,
                                                                        20, 10,
                                                                    ]}
                                                                    colors={[
                                                                        'rose',
                                                                        'orange',
                                                                        'yellow',
                                                                        'emerald',
                                                                    ]}
                                                                    markerValue={
                                                                        model.actualState *
                                                                        100
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Flex>
                                    </div>
                                )}
                            </TabPanel>
                            <TabPanel>
                                <div className="mt-10">
                                    <ComparationTab models={models} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="mt-10">
                                    <ZoneTab models={models} />
                                </div>
                            </TabPanel>
                        </TabPanels>
                    </TabGroup>
                </div>
            </div>
        </div>
    )
}
