import { createContext, useCallback, useReducer } from 'react'

export const ModelContext = createContext()

const modelReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_MODEL':
            return {
                ...state,
                selectedModel: action.payload,
            }
        case 'SET_SELECTED_MODEL_CATEGORIES':
            return {
                ...state,
                selectedModelCategories: action.payload,
            }
        case 'ADD_ELEMENT_PROPS':
            return {
                ...state,
                selectionProps: [...state.selectionProps, action.payload],
            }
        case 'RESET_PROPS':
            return {
                ...state,
                selectionProps: [],
            }
        case 'SET_COLORED_IDS':
            return {
                ...state,
                coloredIds: action.payload,
            }
        case 'SET_FORGE_TOKEN':
            return {
                ...state,
                forgeToken: action.payload,
            }
        case 'SET_NODE_LIST':
            return { ...state, nodeList: action.payload }
        default:
            return { ...state }
    }
}

export const ModelProvider = ({ children }) => {
    const [state, dispatch] = useReducer(modelReducer, {
        selectedModel: '',
        selectedModelCategories: {},
        selectionProps: [],
        coloredIds: [],
        forgeToken: '',
        nodeList: [],
    })

    const setData = useCallback((data, type) => {
        const commands = {
            selectedModel: 'SET_SELECTED_MODEL',
            selectedModelCategories: 'SET_SELECTED_MODEL_CATEGORIES',
            selectionProps: 'ADD_ELEMENT_PROPS',
            resetProps: 'RESET_PROPS',
            coloredIds: 'SET_COLORED_IDS',
            forgeToken: 'SET_FORGE_TOKEN',
            nodeList: 'SET_NODE_LIST',
        }

        if (
            type == 'resetProps' ||
            (isObject(data) && !deepEqual(data, state[type])) ||
            data !== state[type]
        ) {
            dispatch({ type: commands[type], payload: data })
        }
    })

    return (
        <ModelContext.Provider value={{ ...state, setData }}>
            {children}
        </ModelContext.Provider>
    )
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
        return false
    }
    for (const key of keys1) {
        const val1 = object1[key] || object1[Number(key)]
        const val2 = object2[key] || object2[Number(key)]
        const areObjects = isObject(val1) && isObject(val2)
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false
        }
    }
    return true
}

function isObject(object) {
    return object != null && typeof object === 'object'
}
