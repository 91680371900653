import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Asegúrate de importar los estilos de Lightbox
import ortofoto1 from '../img/ortofoto1.jpg';
import ortofoto2 from '../img/ortofoto1.jpg';

const LightboxComponent = () => {
  const images = [
    { src: ortofoto1, name: 'PROG 344-345' },
    { src: ortofoto2, name: 'PROG 345-346' }
  ];
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="flex flex-row justify-center">
    {images.map((image, index) => (
        <div key={index} className="flex flex-col items-center m-4">
          <img
            src={image.src}
            alt={image.name}
            onClick={() => openLightbox(index)}
            className="w-24 h-24 cursor-pointer hover:opacity-80 mb-2 rounded-lg"
          />
          <span className="text-sm">{image.name}</span>
        </div>
      ))}

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          imagePadding={50}
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </div>
  );
};

export default LightboxComponent;
