import { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import Slider from '../function/Slider'
import bgCarrousel from '../img/slider/bgCarrousel.png'
import logoBlack from '../img/logoblack.png'
import logo from '../img/logo.png'
import toast, { Toaster } from 'react-hot-toast'

export const Login = () => {
    return (
        <div class="bg-gray-300 dark:bg-gray-500 min-h-screen flex flex-col justify-center items-center">
            <div>
                <div>
           
                </div>
            </div>
            <div class="">
                <LoginUser />
                {/* <RegisterUser /> */}
            </div>
        </div>
    )
}

const LoginUser = ({}) => {
    const [cookies, setCookie, removeCookie] = useCookies(['access_token'])
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    const logoImageRef = useRef(null)
    const logoImageDarkRef = useRef(null)

    const logout = () => {
        removeCookie('access_token')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('userID')
        navigate('/')
    }
    const handleSubmit = async (event) => {
        event.preventDefault()

        // Concatena la URL base con la ruta específica
        const authUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`
        try {
            const result = await axios.post(
                authUrl,
                {
                    username,
                    password,
                },
                { withCredentials: true }
            )
            setCookie('access_token', result.data.token, { path: '/' })
            

            window.localStorage.setItem('token', result.data.token)
            window.localStorage.setItem('userID', result.data.userID)
            window.localStorage.setItem('companyID', result.data.company)

            if (result.data.userID === undefined) {
                logout()
                toast.error('Access denied')
            } else {
                toast.success('Access granted')
                setTimeout(() => {
                    navigate('/home')
                }, 860)
            }
        } catch (error) {
            console.error(error)
            toast.error('Access denied')
        }
    }

    const isDarkModeEnabled = () => {
        return (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        )
    }
    const updateLogoVisibility = () => {
        const isDarkMode = isDarkModeEnabled()
        if (logoImageRef.current && logoImageDarkRef.current) {
            logoImageRef.current.style.display = isDarkMode ? 'none' : 'block'
            logoImageDarkRef.current.style.display = isDarkMode
                ? 'block'
                : 'none'
        }
    }

    useEffect(() => {
        updateLogoVisibility()
    }, [])

    return (
        <div class="pt-12 pb-14 p-4">
            <Toaster />

            <div class="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl ">
                <div class="w-full lg:w-1/2 bg-white dark:bg-gray-700 dark:border-gray-800 xl:p-4 ">
                    <div class="md:max-w-md xl:p-0 mx-1">
                        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <a
                                href="#"
                                class="flex justify-center items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
                            >
                                <img
                                    class="h-16 pb-2"
                                    src={logo}
                                    alt="Logo Voyansi"
                                    ref={logoImageRef}
                                />
                                <img
                                    class="h-16 pb-2"
                                    src={logoBlack}
                                    alt="Logo Voyansi (Dark)"
                                    ref={logoImageDarkRef}
                                />
                            </a>

                            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Login
                            </h1>
                            <p class="text-xs dark:text-slate-300 text-slate-600"></p>
                            <hr />
                            <form
                                class="space-y-4 md:space-y-6"
                                onSubmit={handleSubmit}
                            >
                                <span class="text-xs dark:text-slate-300 text-slate-600 ">
                                    Enter your email and password to login to
                                    your account.
                                </span>
                                <div>
                                    <label
                                        for="username"
                                        class="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
                                    >
                                        User*
                                    </label>
                                    <input
                                        type="text"
                                        name="username"
                                        id="username"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="name@company.com"
                                        value={username}
                                        onChange={(event) =>
                                            setUsername(event.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        for="password"
                                        class="block mb-2 text-xs font-medium text-gray-900 dark:text-white"
                                    >
                                        Password *
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={password}
                                        onChange={(event) =>
                                            setPassword(event.target.value)
                                        }
                                        placeholder="••••••••"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-3xl focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required
                                    />
                                </div>
                                <div class="flex items-center justify-between">
                                    {/* <!-- Botón alineado a la izquierda --> */}
                                    <button
                                        type="submit"
                                        class="w-32 text-white bg-[#493E98] dark:border-black-200 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-900"
                                    >
                                        Sign in
                                    </button>
                                    {/* <!-- Enlace "Forgot password?" alineado a la derecha --> */}
                                    <a
                                        href="#"
                                        class="text-[#493E98] text-sm font-medium text-primary-600 hover:underline dark:text-[#02CAD5]"
                                    >
                                        Forgot password?
                                    </a>
                                </div>

                                <p class="text-center text-xs font-light text-gray-500 dark:text-gray-300">
                                    Don’t have an account yet?{' '}
                                    <a
                                        href="#"
                                        class="font-medium text-[#493E98] hover:underline dark:text-primary-500  dark:text-[#AF4D83] dark:text-[#FF949A]"
                                    >
                                        Sign up
                                    </a>
                                </p>
                                <p class="text-center text-xs font-light text-gray-500 dark:text-gray-300">
                                    Having problems?{' '}
                                    <a
                                        href="#"
                                        class="font-medium text-[#493E98] hover:underline dark:text-primary-500 dark:text-[#AF4D83]"
                                    >
                                        Contact support.
                                    </a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <div
                    class="hidden lg:block lg:w-1/2 bg-cover"
                    style={{ backgroundImage: `url(${bgCarrousel})` }}
                >
                    <Slider />
                </div>
            </div>
        </div>
    )
}
