import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { useGetCompanyID } from '../../hooks/useGetCompanyID'
import {
    Flex,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Badge,
    CategoryBar,
    BadgeDelta,
} from '@tremor/react'
import { useTranslation } from 'react-i18next'

const ZoneTab = ({ models }) => {
    const [zones, setZones] = useState([])
    const [averageStates, setAverageStates] = useState({})
    const [centersCount, setCentersCount] = useState({})
    const [cookies, _] = useCookies(['access_token'])
    const companyID = useGetCompanyID()
    const { t } = useTranslation()

    useEffect(() => {
        const fetchZones = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/zone/getZones?company=${companyID}`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )

                if (response.status === 200) {
                    setZones(response.data)
                } else {
                    console.error('Error al obtener las zonas')
                }
            } catch (error) {
                console.error('Error al obtener las zonas', error)
            }
        }

        fetchZones()
    }, [companyID, cookies.access_token])

    const calculateZoneData = () => {
        const avgStates = {}
        const centersCountData = {}

        zones.forEach((zone) => {
            const modelsInZone = models.filter(
                (model) => model.category === zone._id
            )
            const totalState = modelsInZone.reduce(
                (sum, model) => sum + model.actualState,
                0
            )
            const average = (totalState / modelsInZone.length) * 100 // Multiplica por 100

            avgStates[zone.nameZone] = isNaN(average)
                ? 'No data'
                : average.toFixed(2)
            centersCountData[zone.nameZone] = modelsInZone.length
        })

        setAverageStates(avgStates)
        setCentersCount(centersCountData)
    }

    useEffect(() => {
        calculateZoneData()
    }, [zones, models])

    const sortedZones = zones.slice().sort((a, b) => {
        const avgA = parseFloat(averageStates[a.nameZone])
        const avgB = parseFloat(averageStates[b.nameZone])
        if (isNaN(avgA) && isNaN(avgB)) return 0
        if (isNaN(avgA)) return 1
        if (isNaN(avgB)) return -1
        return avgB - avgA
    })

    return (
        <div className="ZoneTab shadow-lg md:shadow-day dark:shadow-sky-700">
            <TabGroup>
                <TabPanels className="w-fit mx-auto">
                    <TabPanel>
                        <div className="mt-10">
                            <Flex className="mt-4">
                                <table className="table-fixed bg-white dark:bg-gray-800 text-black dark:text-white rounded-lg shadow-lg">
                                    <thead>
                                        <tr className="bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 text-left">
                                            <th></th>
                                            <th className="px-4 py-2 cursor-pointer">
                                            {t('names')}
                                            </th>
                                            <th className="px-4 py-2">
                                            {t('state')}
                                            </th>
                                            <th className="px-4 py-2">%</th>
                                            <th className="px-4 py-2">
                                            {t('amount')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedZones.map((zone) => (
                                            <tr
                                                key={zone._id}
                                                className={`hover:bg-gray-100 dark:hover:bg-gray-900 transition duration-300`}
                                            >
                                                <td></td>
                                                <td className="px-4 py-2 text-sm">
                                                    <Badge>
                                                        {zone.nameZone}
                                                    </Badge>{' '}
                                                </td>
                                                <td className="px-2 py-2  w-48">
                                                    <CategoryBar
                                                        values={[
                                                            40, 30, 20, 10,
                                                        ]}
                                                        colors={[
                                                            'emerald',
                                                            'yellow',
                                                            'orange',
                                                            'rose',
                                                        ]}
                                                        markerValue={[
                                                            parseFloat(
                                                                averageStates[
                                                                    zone
                                                                        .nameZone
                                                                ]
                                                            ) || 0,
                                                        ]}
                                                        className=" mb-3"
                                                    />
                                                </td>
                                                <td>
                                                    {[
                                                        parseFloat(
                                                            averageStates[
                                                                zone.nameZone
                                                            ]
                                                        ) || 0,
                                                    ]}{' '}
                                                    %
                                                </td>
                                                <td className="px-4 py-2">
                                                    {
                                                        centersCount[
                                                            zone.nameZone
                                                        ]
                                                    }       {t('models')}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Flex>
                        </div>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    )
}

export default ZoneTab
