import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MdAutorenew, MdDone, MdErrorOutline } from 'react-icons/md'
import { BadgeDelta } from '@tremor/react'
import { useTranslation } from 'react-i18next'

// El componente Model recibe dos propiedades: 'model' y 'generalStateData'
const Model = ({ model, generalStateData }) => {
    const { t } = useTranslation()

    // Destructuración de propiedades del objeto 'model'
    const { name, category, thumbnail, address } = model
    // Verifica si 'generalStateData' está definido antes de desestructurar sus propiedades
    const hasDataForCurrentMonth = generalStateData
        ? generalStateData.hasDataForCurrentMonth
        : false
    const hasDataForPreviousMonth = generalStateData
        ? generalStateData.hasDataForPreviousMonth
        : false

    // Estado local para controlar la visibilidad del diálogo de actualización
    const [showUpdateDialog, setShowUpdateDialog] = useState(false)

    // Manejador de eventos para el evento onMouseEnter (cuando el mouse entra en el elemento)
    const handleMouseEnter = () => {
        // Muestra el diálogo de actualización si no hay datos para el mes actual
        if (!hasDataForCurrentMonth) {
            setShowUpdateDialog(true)
        }
    }

    // Manejador de eventos para el evento onMouseLeave (cuando el mouse sale del elemento)
    const handleMouseLeave = () => {
        // Oculta el diálogo de actualización
        setShowUpdateDialog(false)
    }

    const getBadgeDelta = (variantState) => {
        let deltaType = 'unchanged'
        let text = 'Unchanged'

        if (typeof variantState !== 'undefined') {
            if (variantState > 5) {
                deltaType = 'increase'
                text = 'Increase'
            } else if (variantState > 0) {
                deltaType = 'moderateIncrease'
                text = 'Moderate Increase'
            } else if (variantState < -5) {
                deltaType = 'decrease'
                text = 'Decrease'
            } else if (variantState < 0) {
                deltaType = 'moderateDecrease'
                text = 'Moderate Decrease'
            }
        }

        return (
            <BadgeDelta deltaType={deltaType}>
                {text}{' '}
                {typeof model.variantState !== 'undefined'
                    ? `(${model.variantState.toFixed(2)}%)`
                    : ''}
            </BadgeDelta>
        )
    }

    const [imageURL, setImageURL] = useState('')

    // console.log("thumbnail: ", thumbnail)
    useEffect(() => {
        async function fetchImageURL() {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/s3/get-image-url`,
                    {
                        params: {
                            folderName: 'thumbnails',
                            originalName: thumbnail,
                        },
                    }
                )
                setImageURL(response.data.url)
            } catch (error) {
                //   console.error("Error fetching image URL:", error);
            }
        }
        fetchImageURL()
    }, [thumbnail])

    return (
        <div
            className="group relative transform transition duration-500 hover:scale-105"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                id="degrade-card"
                className={`block max-w-sm p-3 rounded-[25px] shadow-[0_3px_10px_rgb(0,0,0,0.2)] shadow-xl shadow-black-500/40 
     border border-stone-400 border-x-stone-400 border-b-stone-400 transform hover:-rotate-1 hover:shadow-lg 
     hover:hover:shadow-[#493E98]  dark:bg-gray-900 dark:border-gray-900`}
                style={{
                    // Aplica un filtro de desenfoque si no hay datos para el mes actual
                    backdropFilter: !hasDataForCurrentMonth
                        ? 'blur(5px)'
                        : 'none',
                }}
            >
                <div className="text-center">
                    <h2 className="text-sm font-semibold text-[#493E98] dark:text-white overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {name}
                    </h2>
                </div>
                <div className="h-28 m-2 relative overflow-hidden rounded-lg">
                    <img
                        src={imageURL}
                        alt="card image"
                        className="absolute inset-0 w-full h-full object-cover transform transition duration-500 group-hover:scale-110"
                    />
                </div>
                <div className="">
                    <div className="flex items-center justify-center">
                        {model && (
                            <div className="">
                                {getBadgeDelta(model.variantState)}
                            </div>
                        )}
                    </div>

                    <div className="mt-1 text-xs text-gray-700 dark:text-gray-300 overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {address ? address.description : ''}
                    </div>
                </div>
                {/* Muestra un icono de actualización si no hay datos para el mes actual, de lo contrario muestra un icono de éxito */}
                {!hasDataForCurrentMonth ? (
                    <div>
                        {/* Agrega un indicador de "actualización necesaria" */}
                        <span className="absolute top-0 right-0 flex h-4 w-4">
                            <span className="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-red-500 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-4 w-4 bg-red-600">
                                <MdAutorenew className="text-white" />
                            </span>
                        </span>
                    </div>
                ) : (
                    <div>
                        {/* Agrega un indicador de "éxito" */}
                        <span className="absolute top-0 right-0 flex h-4 w-4">
                            <span className="absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-55"></span>
                            <span className="relative inline-flex rounded-full h-4 w-4 bg-green-600">
                                <MdDone className="text-white" />
                            </span>
                        </span>
                    </div>
                )}
            </div>
            {/* Muestra el diálogo de actualización si se cumple la condición */}
            {showUpdateDialog && !hasDataForCurrentMonth && (
                <div className="absolute top-0 left-0 right-0 bottom-0 p-4 bg-gray-50 dark:bg-gray-800 shadow-xl rounded-lg flex flex-col justify-center bg-opacity-90 items-center">
                    <div className="flex items-center mb-2">
                        <MdErrorOutline className="text-red-600 text-4xl mr-2" />
                        <p className="text-red-600 font-semibold text-xl">
                            {t('Update')}
                        </p>
                    </div>
                    <p className="text-gray-700 text-sm dark:text-gray-300 mt-2">
                        {t('pleaseUpdate')}
                    </p>
                </div>
            )}
        </div>
    )
}

export default Model
