export function getAllProperties(viewer, setData) {
    var tree = viewer.model.getInstanceTree()
    if (tree === undefined) {
        setTimeout(() => {
            getAllProperties(viewer, setData)
        }, 500)
        return
    }
    var categories = {}
    var promises = []

    tree.enumNodeChildren(tree.getRootId(),function (dbId) {
       
            if (tree.getChildCount(dbId) === 0) {
                var promise = new Promise(function (resolve, reject) {
                    viewer.getProperties(dbId, function (props) {
                        var elementData = {
                            dbId: dbId,
                            name: props.name,
                            externalId: props.externalId,
                        }

                        for (var i = 0; i < props.properties.length; i++) {
                            var propDisplayName =
                                props.properties[i].displayName
                            var propDisplayValue =
                                props.properties[i].displayValue

                            // Si el valor es null, no lo procese
                            if (propDisplayValue === null) continue

                            switch (propDisplayName) {
                                case 'Category':
                                    elementData.categoryValue =
                                        props.properties[i].displayValue
                                    // console.log(props.properties[i].displayValue)

                                    break
                                case 'Width':
                                    elementData.Width =
                                        props.properties[i].displayValue
                                    break
                                case 'Height':
                                    elementData.Height =
                                        props.properties[i].displayValue
                                    break
                                case 'Estado':
                                case 'State':
                                case 'Score':
                                case 'ESTADO':
                                case 'MC-Estado':
                                case 'Condition':
                                    elementData.State =
                                        props.properties[i].displayValue
                                    break
                                case 'Garantía':
                                    elementData.Warranty =
                                        props.properties[i].displayValue
                                    break
                                case 'Eficiencia Energética de Equipos Electromecánicos':
                                    elementData.Efficiency =
                                        props.properties[i].displayValue
                                    break

                                case 'Mark':
                                    elementData.Mark =
                                        props.properties[i].displayValue
                                    break
                                case 'LIMPIEZA':
                                    elementData.LIMPIEZA =
                                        props.properties[i].displayValue
                                    break
                                case 'SOPORTE':
                                    elementData.SOPORTE =
                                        props.properties[i].displayValue
                                    break

                                case 'PINTURA':
                                    elementData.PINTURA =
                                        props.properties[i].displayValue
                                    break

                                case 'POSTE':
                                    elementData.POSTE =
                                        props.properties[i].displayValue
                                    break
                                case 'BRAZO':
                                    elementData.BRAZO =
                                        props.properties[i].displayValue
                                    break
                              case 'Area':
                                    elementData.Area =
                                        props.properties[i].displayValue
                                    break
                                case 'Vidrios Faltantes':
                                    elementData.MissingGlass =
                                        props.properties[i].displayValue
                                    break
                                case 'Cartelería de Evacuación':
                                    elementData.EvacuationSignage =
                                        props.properties[i].displayValue
                                    break
                                case 'Cumplimiento Normativa':
                                    elementData.ComplianceWithStandards =
                                        props.properties[i].displayValue
                                    break
                                case 'Type IfcGUID':
                                    elementData.TypeIfcGUID =
                                        props.properties[i].displayValue
                                    break
                                case 'Baldosas Faltantes':
                                    elementData.MissingTiles =
                                        props.properties[i].displayValue
                                    break
                                case 'Grietas, Rajadura o Fisuras':
                                case 'MC-Grietas, Rajaduras o Fisuras':
                                case 'Cracks':
                                    elementData.CracksOrFissures =
                                        props.properties[i].displayValue
                                    break
                                case 'Apto para Recolección de Agua de Lluvia':
                                    elementData.SuitableForRainwaterHarvesting =
                                        props.properties[i].displayValue
                                    break
                                case 'Zócalos Faltantes':
                                case 'MC-Zócalo':
                                    elementData.MissingBaseboards =
                                        props.properties[i].displayValue
                                    break
                                   
                                    case 'Painted':
                                        elementData.Painted =
                                            props.properties[i].displayValue
                                        break
                                case 'Pintura Faltante':
                                    elementData.MissingPaint =
                                        props.properties[i].displayValue
                                    break
                                case 'Capacidad':
                                    elementData.Capacity =
                                        props.properties[i].displayValue
                                    break
                                case 'Fecha de Vencimiento':
                                    elementData.ExpiryDate =
                                        props.properties[i].displayValue
                                    break
                                case 'Herrajería':
                                case 'MC-Herrajería':
                                    elementData.Ironwork =
                                        props.properties[i].displayValue
                                    break
                                case 'Funciona':
                                    elementData.Works =
                                        props.properties[i].displayValue
                                    break
                                case 'Bajo Consumo':
                                    elementData.LowConsumption =
                                        props.properties[i].displayValue
                                    break
                                case 'Type IfcGUID':
                                    elementData.ifcGUID =
                                        props.properties[i].displayValue
                                    break
                                case 'Comments':
                                case 'Comentarios':

                                    elementData.Comments =
                                        props.properties[i].displayValue
                                    break
                                case 'Humedad':
                                case 'Humidity':
                                case 'MC-Humedad':

                                    elementData.Humity =
                                        props.properties[i].displayValue
                                    break
                            }
                        }

                        var allPropertiesNotNull = Object.values(
                            elementData
                        ).every((value) => value !== null)

                        if (
                            elementData.categoryValue !== null &&
                            allPropertiesNotNull
                        ) {
                            if (!categories[elementData.categoryValue]) {
                                categories[elementData.categoryValue] = []
                            }
                            categories[elementData.categoryValue].push(
                                elementData
                            )
                        }
                        resolve()
                    })
                })
                promises.push(promise)
            }

        },
        true



        
    )

    Promise.all(promises).then(function () {
        if (categories) {
            setData(categories, 'selectedModelCategories')
        }
        window.categories = categories
        console.log(categories)

    })
}
