import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useGetUserID } from '../hooks/useGetUserID'
import { useNavigate } from 'react-router-dom'
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from 'react-google-places-autocomplete'
import { useCookies } from 'react-cookie' // Importa el hook de cookies

export const CreateModel = () => {
    // Obtener el ID de usuario utilizando el hook personalizado useGetUserID
    const userID = useGetUserID()

    // Obtener y almacenar las cookies
    const [cookies, _] = useCookies(['access_token'])

    // Estado para almacenar la imagen en formato base64
    // const [thumbnailBase64, setThumbnailBase64] = useState('')

    // Estado para almacenar los datos del modelo en un objeto
    const [model, setModel] = useState({
        name: '',
        userOwner: userID,
        urn: '',
        projectID: '',
        layout: {
            leftSide: '',
            rightSide: '',
            visor: '',
            insightSide: [],
            bottonSide: [],
        },
        solarAnalysis: {
            energyProduction: '',
            energySaving: '',
            panelArea: '',
            yearsPayback: '',
        },
        thumbnail: 'noimage.png', // Valor predeterminado
        company: '',
        lightningAnalysis: {
            total: '',
            totalUp: '',
            totalDown: '',
            percent9am: '',
            percent9amup: '',
            percent9amdown: '',
            percent3pm: '',
            percent3pmup: '',
            percent3pmdown: '',
        },
        footPrint: {
            area: '',
            totalEmbodiedCarbon: '',
            totalEmbodiedCarbonPerM2: '',
            averageCarEmissionPerYear: '',
            trees: '',
        },
        panellum: '',
        cintooURL: '',
        cintooP: '',
        category: '',
        folderID: '',
    })

    // Estados para controlar la visibilidad de las secciones
    const [showFootPrint, setShowFootPrint] = useState(false)
    const [showLightningAnalysis, setShowLightningAnalysis] = useState(false)
    const [showSolarAnalysis, setShowSolarAnalysis] = useState(false)

    // Funciones para cambiar la visibilidad de las secciones
    const toggleFootPrint = () => setShowFootPrint(!showFootPrint)
    const toggleLightningAnalysis = () =>
        setShowLightningAnalysis(!showLightningAnalysis)
    const toggleSolarAnalysis = () => setShowSolarAnalysis(!showSolarAnalysis)
    const [showLayoutFields, setShowLayoutFields] = useState(false)
    const [companies, setCompanies] = useState([]) // Para almacenar la lista de compañías
    const [availableCategories, setAvailableCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const [showCategorySelect, setShowCategorySelect] = useState(false)
    const [selectedRevitModel, setSelectedRevitModel] = useState('')
    const [rvtmodels, setrvtModels] = useState([])

    // Estado para almacenar la dirección seleccionada desde Google Places Autocomplete
    const [address, setAddress] = useState('')

    // Obtiene la función de navegación desde react-router-dom
    const navigate = useNavigate()

    // Maneja el cambio en los campos de entrada de texto
    const handleChange = (event) => {
        const { name, value } = event.target

        // Actualiza el estado del modelo con el nuevo valor
        setModel({ ...model, [name]: value })
    }

    // Maneja el cambio en los campos de la sección "footPrint"
    const handleFootPrintChange = (event) => {
        const { name, value } = event.target

        // Actualiza el estado del modelo con el nuevo valor en la sección "footPrint"
        setModel((prevModel) => ({
            ...prevModel,
            footPrint: { ...prevModel.footPrint, [name]: value },
        }))
    }

    // Maneja el cambio en los campos de la sección "solarAnalysis"
    const handleSolarAnalysisChange = (event) => {
        const { name, value } = event.target

        // Actualiza el estado del modelo con el nuevo valor en la sección "solarAnalysis"
        setModel((prevModel) => ({
            ...prevModel,
            solarAnalysis: {
                ...prevModel.solarAnalysis,
                [name]: value,
            },
        }))
    }

    // Maneja el cambio en los campos de la sección "lightningAnalysis"
    const handleLightningAnalysis = (event) => {
        const { name, value } = event.target

        // Actualiza el estado del modelo con el nuevo valor en la sección "lightningAnalysis"
        setModel((prevModel) => ({
            ...prevModel,
            lightningAnalysis: {
                ...prevModel.lightningAnalysis,
                [name]: value,
            },
        }))
    }

    const handleGetRvtModels = async () => {
        try {
            const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
            const tokenResponse = await axios.get(`${baseUrl}/forge/token`, {
                headers: { authorization: cookies.access_token },
            })

            const accessToken = tokenResponse.data.access_token
            const itemsResponse = await axios.post(
                `${baseUrl}/forge/getItemsInFolder`,
                {
                    projectId: 'b.' + model.projectID,
                    accessToken: accessToken,
                    folderId: 'urn:adsk.wipprod:fs.folder:co.' + model.folderID,
                },
                {
                    headers: { authorization: cookies.access_token },
                }
            )

            console.log(itemsResponse)
            if (itemsResponse) {
                setrvtModels(itemsResponse.data)
            }
            console.log('RVT response:', itemsResponse.data)
        } catch (error) {
            console.error('Error obtaining rvt models:', error)
        }
    }

    const handleRevitModelChange = (e) => {
        const value = e.target.value
        setSelectedRevitModel(value)
        model.urn = value
        //setFormData({ ...formData, urnItemId: value });
    }

    // Efecto para obtener la lista de compañías desde la API cuando cambian las cookies
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/companies/getCompanies`,
                {
                    // Agrega las cookies a las cabeceras de la solicitud
                    headers: { authorization: cookies.access_token },
                }
            )
            .then((response) => {
                // Almacena la lista de compañías en el estado
                setCompanies(response.data)
            })
            .catch((error) => {
                console.error('Error al obtener las compañías:', error)
            })
    }, [cookies])

    useEffect(() => {
        if (model.company) {
          const idCompany = model.company;
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_BASE_URL}/zone/getZones?company=${idCompany}`,
              {
                headers: { authorization: cookies.access_token },
              }
            )
            .then((response) => {
              setAvailableCategories(response.data);
            })
            .catch((error) => {
              console.error('Error al obtener las categorías:', error);
            });
        }
      }, [model.company, cookies]);
      
    // Maneja el cambio en el campo de selección "company"
    const handleCompanyChange = (event) => {
        // Actualiza el estado del modelo con la compañía seleccionada
        setModel({
            ...model,
            company: event.target.value,
        })
        // Muestra el segundo select
        setShowCategorySelect(true)
        // Reinicia el valor de las categorías seleccionadas
        setSelectedCategory('')

        // Muestra los campos de selección adicionales solo si se selecciona una opción en "company"
        setShowLayoutFields(event.target.value !== '')
    }

    // Maneja la selección de una dirección desde Google Places Autocomplete
    const handleSelectAddress = async (address) => {
        const countryTerm = address.value.terms.find(
            (term) => term.types && term.types.includes('country')
        )
        const country = countryTerm ? countryTerm.value : ''
        const description = address.value.description
        const place_id = address.value.place_id
        let location

        try {
            const results = await geocodeByPlaceId(place_id)
            location = results[0].geometry.location // { lat: ..., lng: ... }
        } catch (error) {
            console.log(error)
        }

        // Actualiza el estado del modelo con la dirección seleccionada
        setModel((prevModel) => ({
            ...prevModel,
            address: {
                description: description,
                place_id: place_id,
                country: country || description.split(',').pop().trim(),
                lat: location ? location.lat() : null,
                lng: location ? location.lng() : null,
            },
        }))

        setAddress(address)
    }

    // Maneja el envío del formulario
    const handleSubmit = async (event) => {
        event.preventDefault()
        const updatedModel = {
            ...model,
        }

        try {
            // Realiza una solicitud POST para cargar el modelo en el servidor
            await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/models`,
                updatedModel,
                {
                    headers: { authorization: cookies.access_token },
                }
            )

            // Muestra una alerta de éxito y redirige al usuario a la página de inicio
            alert('Model Upload')
            navigate('/home')
        } catch (error) {
            console.error(error)
        }
    }

    // Función para convertir un archivo a formato base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(file)
        })
    }

    // Maneja el cambio en el campo de selección de imagen de modelo
    const handleThumbnailChange = async (event) => {
        const file = event.target.files[0]
        if (file) {
            try {
                const base64Image = await convertToBase64(file)

                // Actualiza el valor de "thumbnail" en el estado "model" con el valor en base64
                setModel((prevModel) => ({
                    ...prevModel,
                    thumbnail: base64Image,
                }))
            } catch (error) {
                console.error(error)
            }
        }
    }

    // Estado para controlar la selección en el campo de visor
    const [selectedVisor, setSelectedVisor] = useState('')

    // Maneja el cambio en los campos de la sección "layout"
    const handleLayoutChange = (event) => {
        setSelectedVisor(event.target.value)
        const { name, value, options } = event.target

        if (name === 'leftSide' || name === 'rightSide' || name === 'visor') {
            // Selectores simples (leftSide, rightSide, visor), actualiza el valor directamente como string
            setModel((prevModel) => ({
                ...prevModel,
                layout: {
                    ...prevModel.layout,
                    [name]: value,
                },
            }))
        } else if (options && options.length > 1) {
            // Selector múltiple, crea un array de valores seleccionados
            const selectedValues = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value)

            setModel((prevModel) => ({
                ...prevModel,
                layout: {
                    ...prevModel.layout,
                    [name]: selectedValues,
                },
            }))
        }
    }
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-800 dark:text-gray-200">
            <div className="w-full max-w-lg bg-white dark:bg-gray-800 rounded shadow-lg">
                <form className="px-8 py-8" onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label
                            htmlFor="name"
                            className="block text-sm font-bold mb-2 "
                        >
                            Project Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={model.name}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500"
                        />
                    </div>

                    <div class="mb-6">
                        <label class="block  text-sm font-bold mb-2  ">
                            Address
                        </label>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                            selectProps={{
                                value: address,
                                onChange: handleSelectAddress, // Añade el manejador al seleccionar una dirección
                            }}
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="visor"
                            className="block mb-2 text-sm font-medium dark:text-white"
                        >
                            Visor
                        </label>
                        <select
                            id="visor"
                            name="visor"
                            onChange={handleLayoutChange}
                            value={selectedVisor}
                            className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            <option value="forgeViewer">forgeViewer</option>
                            <option value="ifcViewer">ifcViewer</option>
                        </select>
                    </div>
                    {/* Mostrar el campo de entrada solo cuando se selecciona "forgeViewer" */}
                    {selectedVisor === 'forgeViewer' && (
                        <div>
                            <div className="mb-6">
                                <label
                                    htmlFor="projectID"
                                    className="block text-sm font-bold mb-2"
                                >
                                    Project ID
                                </label>
                                <input
                                    type="text"
                                    id="projectID"
                                    name="projectID"
                                    value={model.projectID}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="folderID"
                                    className="block  text-sm font-bold mb-2"
                                >
                                    Folder ID
                                </label>
                                <input
                                    type="text"
                                    id="folderID"
                                    name="folderID"
                                    value={model.folderID}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-4 mt-4">
                                <button
                                    onClick={handleGetRvtModels}
                                    className="p-1 w-40 bg-blue-500 text-white rounded-md"
                                >
                                    Consultar modelos
                                </button>
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="visor"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Revit Model
                                </label>
                                <select
                                    id="rvtModel"
                                    name="rvtModel"
                                    onChange={handleRevitModelChange}
                                    value={selectedRevitModel}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    {rvtmodels.map((rvtmodel) => (
                                        <option
                                            key={rvtmodel.id}
                                            value={rvtmodel.id}
                                        >
                                            {rvtmodel.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-6">
                                <label
                                    htmlFor="urn"
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                    URN
                                </label>
                                <input
                                    type="text"
                                    id="urn"
                                    name="urn"
                                    value={model.urn}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                        </div>
                    )}

                    {/* <div class="mb-4">
                        <label
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            for="thumbnail"
                        >
                            Upload Thumbnail
                        </label>
                        <input
                            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            id="thumbnail"
                            name="thumbnail"
                            type="file"
                            onChange={handleThumbnailChange}
                        />
                    </div> */}
                    <div class="mb-4">
                        <label
                            class="block  text-sm font-bold mb-2"
                            for="cintooURL"
                        >
                            cintoo URL
                        </label>
                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="cintooURL"
                            name="cintooURL"
                            value={model.cintooURL} // Vincula el valor del campo de entrada al estado del modelo
                            onChange={handleChange} // Agrega el controlador onChange
                        ></input>
                    </div>
                    <div class="mb-4">
                        <label
                            class="block  text-sm font-bold mb-2"
                            for="cintooP"
                        >
                            cintoo Password
                        </label>
                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="cintooP"
                            name="cintooP"
                            value={model.cintooP} // Vincula el valor del campo de entrada al estado del modelo
                            onChange={handleChange} // Agrega el controlador onChange
                        ></input>
                    </div>

                    <div className="mb-4">
                        <label
                            htmlFor="company"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Company
                        </label>
                        <select
                            id="company"
                            name="company"
                            value={model.company}
                            onChange={handleCompanyChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="" disabled>
                                Selecciona una opción
                            </option>
                            {companies.map((company) => (
                                <option key={company._id} value={company._id}>
                                    {company.nameCompany}
                                </option>
                            ))}
                        </select>
                    </div>

                    {showCategorySelect && (
                        <div className="mb-4">
                            <label
                                htmlFor="category"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Category
                            </label>
                            <select
                                id="category"
                                name="category" 
                                value={model.category} // Usar model.category en lugar de selectedCategory
                                onChange={handleChange} // Usar la función handleChange
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option value="" disabled>
                                    Selecciona una categoría
                                </option>
                                {availableCategories.map((category) => (
                                    <option key={category._id} value={category._id}>
                                        {category.nameZone}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Mostrar los campos de selección adicionales solo si showLayoutFields es verdadero */}
                    {showLayoutFields && (
                        <>
                            <div class="mb-4">
                                <label
                                    for="leftSide"
                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Left Side
                                </label>
                                <select
                                    id="leftSide"
                                    name="leftSide"
                                    onChange={handleLayoutChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                    <option value="" disabled selected>
                                        Selecciona una opción
                                    </option>
                                    <option value="statePanel">
                                        State panel
                                    </option>
                                    <option value="stateScore">
                                        State Score
                                    </option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label
                                    for="rightSide"
                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Right Side
                                </label>
                                <select
                                    id="rightSide"
                                    name="rightSide"
                                    onChange={handleLayoutChange}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                    <option value="" disabled selected>
                                        Selecciona una opción
                                    </option>
                                    <option value="statePanel">
                                        State panel
                                    </option>
                                    <option value="stateScore">
                                        State Score
                                    </option>
                                </select>
                            </div>
                        </>
                    )}
                    <div class="mb-4">
                        <label
                            for="insightSide"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Insight
                        </label>
                        <select
                            multiple
                            id="insightSide"
                            name="insightSide"
                            onChange={handleLayoutChange}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="insight">Insight</option>
                            <option value="panellum">360 panellum</option>
                            <option value="googleMaps">Google maps</option>
                            <option value="galleryImage">gallery Image</option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <label
                            for="bottonSide"
                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Bottom
                        </label>
                        <select
                            multiple
                            id="bottonSide"
                            name="bottonSide"
                            onChange={handleLayoutChange}
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="panellum">360 panellum</option>
                            <option value="googleMaps">Google maps</option>
                            <option value="insight">Insight</option>
                            <option value="galleryImage">gallery Image</option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <label
                            class="block text-sm font-bold mb-2"
                            for="panellum"
                        >
                            Pannellum
                        </label>
                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="panellum"
                            name="panellum"
                            value={model.panellum} // Vincula el valor del campo de entrada al estado del modelo
                            onChange={handleChange} // Agrega el controlador onChange
                        ></input>
                    </div>
                    <div className="mb-6 border border-gray-300 rounded p-4  bg-red-100">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Lightning Analysis
                            <button
                                type="button"
                                className="ml-2 text-blue-500"
                                onClick={toggleLightningAnalysis}
                            >
                                {showLightningAnalysis ? 'Ocultar' : 'Mostrar'}
                            </button>
                        </label>
                        {showLightningAnalysis && (
                            <div>
                                <div className="mb-6 ">
                                    <input
                                        type="text"
                                        name="total"
                                        value={model.lightningAnalysis.total}
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="totalUp"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        Total Up
                                    </label>
                                    <input
                                        type="text"
                                        name="totalUp"
                                        value={model.lightningAnalysis.totalUp}
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="totalDown"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        Total Down
                                    </label>
                                    <input
                                        type="text"
                                        name="totalDown"
                                        value={
                                            model.lightningAnalysis.totalDown
                                        }
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="percent9am"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        9am
                                    </label>
                                    <input
                                        type="text"
                                        name="percent9am"
                                        value={
                                            model.lightningAnalysis.percent9am
                                        }
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="percent9amup"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        9am Up
                                    </label>
                                    <input
                                        type="text"
                                        name="percent9amup"
                                        value={
                                            model.lightningAnalysis.percent9amup
                                        }
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="percent9down"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        9am Down
                                    </label>
                                    <input
                                        type="text"
                                        name="percent9down"
                                        value={
                                            model.lightningAnalysis.percent9down
                                        }
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="percent3pm"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        3pm
                                    </label>
                                    <input
                                        type="text"
                                        name="percent3pm"
                                        value={
                                            model.lightningAnalysis.percent3pm
                                        }
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="percent3pmup"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        3pm Up
                                    </label>
                                    <input
                                        type="text"
                                        name="percent3pmup"
                                        value={
                                            model.lightningAnalysis.percent3pmup
                                        }
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 mb-2"
                                    />
                                </div>
                                <div className="mb-6 ">
                                    <label
                                        htmlFor="percent3pmdown"
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                    >
                                        3pm Down
                                    </label>
                                    <input
                                        type="text"
                                        name="percent3pmdown"
                                        value={
                                            model.lightningAnalysis
                                                .percent3pmdown
                                        }
                                        onChange={handleLightningAnalysis}
                                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mb-6 border border-gray-300 rounded p-4  bg-green-100">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Solar Analysis
                            <button
                                type="button"
                                className="ml-2 text-blue-500"
                                onClick={toggleSolarAnalysis}
                            >
                                {showSolarAnalysis ? 'Ocultar' : 'Mostrar'}
                            </button>
                        </label>
                        {showSolarAnalysis && (
                            <div>
                                <div class="mb-4">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="energyProduction"
                                    >
                                        Energy Production
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="solarAnalysis.energyProduction"
                                        name="energyProduction"
                                        value={
                                            model.solarAnalysis.energyProduction
                                        }
                                        onChange={handleSolarAnalysisChange}
                                    />
                                </div>
                                <div class="mb-4">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="solarAnalysis.energySaving"
                                    >
                                        Energy Saving
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="solarAnalysis.energySaving"
                                        name="energySaving"
                                        value={model.solarAnalysis.energySaving}
                                        onChange={handleSolarAnalysisChange}
                                    />
                                </div>
                                <div class="mb-4">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="solarAnalysis.panelArea"
                                    >
                                        Panel Area
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="solarAnalysis.panelArea"
                                        name="panelArea"
                                        value={model.solarAnalysis.panelArea}
                                        onChange={handleSolarAnalysisChange}
                                    />
                                </div>
                                <div class="mb-4">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="solarAnalysis.yearsPayback"
                                    >
                                        Years Payback
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="solarAnalysis.yearsPayback"
                                        name="yearsPayback"
                                        value={model.solarAnalysis.yearsPayback}
                                        onChange={handleSolarAnalysisChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mb-6 border border-gray-300 rounded p-4  bg-green-100">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Carbon Footprint
                            <button
                                type="button"
                                className="ml-2 text-blue-500"
                                onClick={toggleFootPrint}
                            >
                                {showFootPrint ? 'Ocultar' : 'Mostrar'}
                            </button>
                        </label>
                        {showFootPrint && (
                            <div>
                                <div class="mb-2">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="area"
                                    >
                                        Area
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="area"
                                        name="area"
                                        value={model.footPrint.area}
                                        onChange={handleFootPrintChange}
                                    ></input>
                                </div>
                                <div class="mb-2">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="totalEmbodiedCarbon"
                                    >
                                        Total Embodied Carbon
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="totalEmbodiedCarbon"
                                        name="totalEmbodiedCarbon"
                                        value={
                                            model.footPrint.totalEmbodiedCarbon
                                        }
                                        onChange={handleFootPrintChange}
                                    ></input>
                                </div>
                                <div class="mb-2">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="totalEmbodiedCarbonPerM2"
                                    >
                                        Total Embodied Carbon / m2
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="totalEmbodiedCarbonPerM2"
                                        name="totalEmbodiedCarbonPerM2"
                                        value={
                                            model.footPrint
                                                .totalEmbodiedCarbonPerM2
                                        }
                                        onChange={handleFootPrintChange}
                                    ></input>
                                </div>
                                <div class="mb-2">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="averageCarEmissionPerYear"
                                    >
                                        Average Car Emission Per Year
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="averageCarEmissionPerYear"
                                        name="averageCarEmissionPerYear"
                                        value={
                                            model.footPrint
                                                .averageCarEmissionPerYear
                                        }
                                        onChange={handleFootPrintChange}
                                    ></input>
                                </div>
                                <div class="mb-2">
                                    <label
                                        class="block text-gray-700 text-sm font-bold mb-2"
                                        for="trees"
                                    >
                                        Trees
                                    </label>
                                    <input
                                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="trees"
                                        name="trees"
                                        value={model.footPrint.trees}
                                        onChange={handleFootPrintChange}
                                    ></input>
                                </div>
                            </div>
                        )}
                    </div>
                    <div class="mt-6 flex items-center justify-end">
                        <button
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
