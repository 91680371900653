import huella from '../img/huella.png'
import co2 from '../img/co2.png'
import tree from '../img/tree.png'
import socialCarbon from '../img/socialCarbon.png'
import circulo from '../img/circulo.png'

import React from 'react'

const Insight = ({  value }) => {


    return (
        <div className="flex flex-col lg:flex-row bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-100 shadow-xl rounded-lg p-4 md:p-6 lg:pt-2 lg:pb-2 my-2 md:my-4 mx-2 sm:mx-4 md:mx-8 mb-10 overflow-hidden">

        <div className="w-full md:w-1/3 p-4 my-8 md:ml-8">
      
          <h2 className="text-3xl font-extrabold mb-8 text-center">
            Análisis Solar
          </h2>
      
          <div className="mb-6">
            <div className="flex items-center">
              <img src={circulo} alt="circulo" className="w-12 h-12 mr-4" />
              <p className="text-gray-600 dark:text-gray-300 text-lg">
                PV Producción de energía:{' '}
                <span className="font-bold">
                  {value?.solarAnalysis?.energyProduction}
                </span>{' '}
                kWh/ Año
              </p>
            </div>
          </div>
      
          <div className="mb-6">
            <div className="flex items-center">
              <img src={circulo} alt="circulo" className="w-12 h-12 mr-4" />
              <p className="text-gray-600 dark:text-gray-300 text-lg">
                <span className="font-bold">
                  ${value?.solarAnalysis?.energySaving}
                </span>{' '}
                de energía ahorrada.
              </p>
            </div>
          </div>
      
          <div className="mb-6">
            <div className="flex items-center">
              <img src={circulo} alt="circulo" className="w-12 h-12 mr-4" />
              <p className="text-gray-600 dark:text-gray-300 text-lg">
                Compensación energética del edificio{' '}
                <span className="font-bold">
                  {value?.solarAnalysis?.panelArea}
                </span>{' '}
                m² PV panel area
              </p>
            </div>
          </div>
      
          <div className="mb-6">
            <div className="flex items-center">
              <img src={circulo} alt="circulo" className="w-12 h-12 mr-4" />
              <p className="text-gray-600 dark:text-gray-300 text-lg">
                {' '}
                <span className="font-bold">
                  {value?.solarAnalysis?.yearsPayback}
                </span>{' '}
                años compensación.
              </p>
            </div>
          </div>
      
        </div>
      
        <div className="w-full md:w-1/2 p-6 my-8 md:mx-auto bg-white dark:bg-gray-800 shadow-lg rounded-lg border border-gray-200 dark:border-gray-600">
      
          <div className="bg-gradient-to-r from-violet-600 to-green-400 p-4 rounded-lg text-white">
            <h2 className="text-3xl font-extrabold mb-2">
              Huella de carbono
            </h2>
            <h3 className="text-xl font-semibold">
              Área:{' '}
              <span className="font-bold text-2xl">
                {value?.footPrint?.area}
              </span>{' '}
              m²
            </h3>
          </div>
      
          <div className="mb-2 mt-4">
            <div className="flex items-center">
              <img src={huella} alt="huella" className="w-16 h-16 mr-4" />
              <div>
                <p className="text-gray-700 dark:text-gray-300">
                  Total carbono embebido :{' '}
                  <span className="font-bold">
                    {value?.footPrint?.totalEmbodiedCarbon}
                  </span>{' '}
                  tCO2
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  Total carbono embebido /m2:{' '}
                  <span className="font-bold">
                    {value?.footPrint?.totalEmbodiedCarbonPerM2}
                  </span>{' '}
                  tCO2/m²
                </p>
              </div>
            </div>
          </div>
      
          <div className="mb-2">
            <div className="flex items-center">
              <img src={co2} alt="CO2" className="w-16 h-16 mr-4" />
              <p className="text-gray-700 dark:text-gray-300">
                Es igual a {' '}
                <span className="font-bold">
                  {value?.footPrint?.averageCarEmissionPerYear}
                </span>{' '}
                emisiones promedio de autos por año.
              </p>
            </div>
          </div>
      
          <div className="mb-2">
            <div className="flex items-center">
              <img src={tree} alt="tree" className="w-16 h-16 mr-4" />
              <p className="text-gray-700 dark:text-gray-300">
                Es igual a {' '}
                <span className="font-bold">
                  {value?.footPrint?.averageCarEmissionPerYear}
                </span>{' '}
                árboles a crecer por 30 años.
              </p>
            </div>
          </div>
      
          <div className="mb-2">
            <div className="flex items-center">
              <img
                src={socialCarbon}
                alt="socialCarbon"
                className="w-16 h-16 mr-4"
              />
              <p className="text-gray-700 dark:text-gray-300">
                Los costos sociales del carbono son :{' '}
                <span className="font-bold">230768.5  $</span> total
              </p>
            </div>
          </div>
      
        </div>
      
        <div className="w-full md:w-1/3 p-4 my-8 md:ml-8">
      
          <div className="mb-6">
            <h2 className="text-4xl font-extrabold mb-6">
              Análisis Lumínico
            </h2>
          </div>
      
          <div className="mb-2">
            <div className="flex items-center">
              <img src={huella} alt="huella" className="w-16 h-16 mr-4" />
              <div>
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-bold text-green-500">
                    {value?.lightningAnalysis?.total}
                  </span>{' '}
                  % del edificio pasó el análisis
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-bold  text-red-500">
                    {value?.lightningAnalysis?.totalDown}
                  </span>{' '}
                  % por debajo del umbral
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-bold text-yellow-500">
                    {value?.lightningAnalysis?.totalUp}
                  </span>{' '}
                  % por encima del umbral
                </p>
              </div>
            </div>
          </div>
      
          <div className="mb-2">
            <div className="flex items-center">
              <img src={circulo} alt="circulo" className="w-12 h-12 mr-8" />
              <div>
                <p className="text-xl font-extrabold text-indigo-700 dark:text-gray-100 mb-2">
                  9:00 am 18 julio
                </p>
                <p className="text-gray-600 dark:text-gray-300 text-lg mb-2">
                  GHI 13 DNI 5 DHI 9
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-bold  text-green-500">
                    {value?.lightningAnalysis?.percent9am}
                  </span>{' '}
                  % pasa el análisis
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-bold  text-red-500">
                    {value?.lightningAnalysis?.percent9amdown}
                  </span>{' '}
                  % por debajo del umbral
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-bold text-yellow-500">
                    {value?.lightningAnalysis?.percent9amup}
                  </span>{' '}
                  % por encima del umbral
                </p>
              </div>
            </div>
          </div>
      
          <div className="flex items-center">
            <img src={circulo} alt="circulo" className="w-12 h-12 mr-8" />
            <div>
              <p className="text-xl text-2xl font-extrabold text-indigo-700 dark:text-gray-100 mb-2">
                3:00 pm 21 septiembre
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                GHI 644 DNI 739 DHI 88
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                <span className="font-bold text-green-500">
                  {value?.lightningAnalysis?.percent3pm}
                </span>{' '}
                % pasa el análisis
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                <span className="font-bold  text-red-500">
                  {value?.lightningAnalysis?.percent3pmdown}
                </span>{' '}
                % por debajo del umbral
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                <span className="font-bold text-yellow-500">
                  {value?.lightningAnalysis?.percent3pmup}
                </span>{' '}
                % por encima del umbral
              </p>
            </div>
          </div>
      
        </div>
      
      </div>
      
    )
}

export default Insight
