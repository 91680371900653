import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Card,
    Flex,
    Metric,
    ProgressCircle,
} from '@tremor/react'


const StructuralState = ({ data, category }) => {
    const { t } = useTranslation()

    // Obtener la fecha actual
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // Los meses en JavaScript son 0-indexados

    // Inicializar variables para el promedio
    let generalStateSum = 0
    let matchingTiles = []

    if (category.length === 0) {
        // Si no se especifica una categoría, incluir todas las tiles
        matchingTiles = data.filter(
            (tile) => tile.tile[currentYear]?.[currentMonth]
        )
    } else {
        // Si se especifica una categoría, incluir solo las tiles que coincidan con la categoría
        console.log('la categoria es ' + category)
        console.log(data)
        matchingTiles = data.filter(
            (tile) =>
                tile.tile[currentYear]?.[currentMonth] &&
                category.includes(tile.category)
        )
    }

    // Calcular la suma de los estados generales
    matchingTiles.forEach((tile) => {
        const tileData = tile.tile[currentYear][currentMonth]
        generalStateSum += tileData.StructuralState
    })

    // Calcular el promedio dividiendo la suma total por el número de coincidencias
    const numberOfMatches = matchingTiles.length
    const generalStateAverage =
        numberOfMatches > 0 ? generalStateSum / numberOfMatches : 0
    console.log(
        'El resultado final es ' +
            generalStateAverage +
            ' de ' +
            numberOfMatches +
            ' modelos'
    )
    // console.log(data)
    return (
        <div className="rounded-xl bg-gradient-to-r p-1 from-[#6EE7B7] via-[#3B82F6] to-[#9333EA] m-1 w-full dark:shadow-2xl	dark:shadow-zinc-700	">

      <Card className="">
                                    <div className="flex flex-col text-center	 justify-between">

      <Flex className="mb-3" justifyContent="between" alignItems="center">
                <Metric> {t('structuralText')} </Metric>
            </Flex>

            <ProgressCircle
                value={(generalStateAverage * 100).toFixed(0)}
                size="md"
                strokeWidth="8"
                className="mb-3" 
                color="indigo" 

            >
                  <span className="dark:text-white font-medium">
                    {(generalStateAverage * 100).toFixed(0)}
                </span>
            </ProgressCircle>
</div>
        </Card>
        </div>
    )
}

export default StructuralState

{
    /* <svg className="w-6 h-6 fill-current text-red-700 transform rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
<path fillRule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
</svg> */
}
