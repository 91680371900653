import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import CreateCategories from '../components/create-companies/CreateCategories';

export const CreateCompanies = () => {
  const [formDataPage1, setFormDataPage1] = useState({
    nameCompany: '',
    layout: { tilesSide: [], chartSide: [] },
  });
  const [formDataPage2, setFormDataPage2] = useState({
    category: [],
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [cookies, _] = useCookies(['access_token']);
  const [currentSection, setCurrentSection] = useState(1);
  const [error, setError] = useState('');
  const [company, setCompany] = useState(null);

  const handleChangePage1 = (e) => {
    const { name, value } = e.target;
    setFormDataPage1({ ...formDataPage1, [name]: value });
  };

  const handleMultipleSelectPage1 = (e) => {
    const { name, options } = e.target;
    const values = Array.from(options)
      .filter((o) => o.selected)
      .map((o) => o.value);

    if (name === 'layout.tilesSide') {
      setFormDataPage1({ ...formDataPage1, layout: { ...formDataPage1.layout, tilesSide: values } });
    } else if (name === 'layout.chartSide') {
      setFormDataPage1({ ...formDataPage1, layout: { ...formDataPage1.layout, chartSide: values } });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const imageToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => {
        const base64Image = reader.result;
        resolve(base64Image);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const sendDataToBackendPage1 = async () => {
    try {
      let thumbnailBase64 = null;

      if (selectedImage) {
        const base64Image = await imageToBase64(selectedImage);
        thumbnailBase64 = base64Image;
      }

      const dataToSend = {
        nameCompany: formDataPage1.nameCompany,
        layout: {
          tilesSide: formDataPage1.layout.tilesSide,
          chartSide: formDataPage1.layout.chartSide,
        },
        thumbnail: thumbnailBase64,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/companies/createCompany`,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: cookies.access_token,
          },
        }
      );
      const companyData = response.data.company; // Supongo que el servidor devuelve la compañía en la propiedad 'company'
      setCompany(companyData); // Establecer la compañía en el estado

      console.log('Datos enviados con éxito al backend (Página 1):', response.data);
      setError('');
      setCurrentSection(2);
    } catch (err) {
      console.error('Error al enviar datos al backend (Página 1):', err);
      setError('Error al enviar datos al servidor. Por favor, inténtalo de nuevo.');
    }
  };



  const handleSubmitPage1 = async (e) => {
    e.preventDefault();
  
    // Verificar que se ha ingresado el nombre de la compañía
    if (!formDataPage1.nameCompany) {
      setError('Por favor, ingresa el nombre de la compañía.');
      return;
    }
  
    // Verificar que se ha seleccionado una imagen
    if (!selectedImage) {
      setError('Por favor, selecciona una imagen para la compañía.');
      return;
    }
  
    await sendDataToBackendPage1();
  };


  return (
    <div className="mx-auto h-screen dark:bg-gray-800 flex items-center justify-center dark:text-gray-200">
      {currentSection === 1 && (
        <form onSubmit={handleSubmitPage1} className="space-y-4">
          <div className="flex flex-col items-center">
            <label className="font-semibold text-lg">Nombre de la Compañía:</label>
            <input
              type="text"
              name="nameCompany"
              onChange={handleChangePage1}
              className="w-full p-2 border rounded mx-auto dark:text-white dark:bg-gray-700"
            />
          </div>

          <div className="flex flex-col items-center">
            <label className="font-semibold text-lg">Layout (Tiles Side):</label>
            <select
              name="layout.tilesSide"
              multiple
              onChange={handleMultipleSelectPage1}
              className=" w-full p-2 border rounded mx-auto dark:text-white dark:bg-gray-700"
            >
  <option value="GeneralState">GeneralState</option>
            <option value="projectCount">projectCount</option>
            <option value="EdilicialState">EdilicialState</option>
            <option value="ElectricalState">ElectricalState</option>
            <option value="StructuralState">StructuralState</option>
            <option value="WaterState">WaterState</option>
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label className="font-semibold text-lg">Layout (Chart Side):</label>
            <select
              name="layout.chartSide"
              multiple
              onChange={handleMultipleSelectPage1}
              className="w-full p-2 border rounded mx-auto dark:text-white dark:bg-gray-700"
            >
  <option value="GeneralState">GeneralState</option>
            <option value="projectCount">projectCount</option>
            <option value="EdilicialState">EdilicialState</option>
            <option value="ElectricalState">ElectricalState</option>
            <option value="StructuralState">StructuralState</option>
            <option value="WaterState">WaterState</option>
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label className="font-semibold text-lg">Icono de la Compañía:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className=" mx-auto dark:text-white dark:bg-gray-700"
            />
          </div>

          {error && <p className="text-red-500">{error}</p>}

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded dark:bg-blue-800 mx-auto block"
          >
            Siguiente
          </button>
        </form>
      )}

      {currentSection === 2 && (
  <CreateCategories formDataPage2={formDataPage2} setFormDataPage2={setFormDataPage2} cookies={cookies} company={company} />
  )}
    </div>
  );
};
