import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import imgSlider1 from '../img/slider/imgSlider1.png'
import imgSlider2 from '../img/slider/imgSlider2.png'
import imgSlider3 from '../img/slider/imgSlider3.png'

const Slider2 = () => {
    // Configuración del carrusel
    const settings = {
        dots: true, // Muestra los puntos de navegación en la parte inferior
        infinite: true, // Infinito, regresa al primer slide después del último
        speed: 1000, // Velocidad de la transición en milisegundos
        slidesToShow: 1, // Cuántas diapositivas se deben mostrar a la vez
        slidesToScroll: 1, // Cuántas diapositivas se deben desplazar a la vez
        autoplay: true, // Habilitar el autoplay
        autoplaySpeed: 3000, // Tiempo en milisegundos antes de cambiar al siguiente slide
        arrows: false,
       
    }

    return (
        <div className="h-full flex flex-wrap gap-6">
            <div className="w-full">
                <Slider {...settings}>
                    {/* Slide 1 */}
                    <div className="relative w-full pt-28">
                        <img
                            src={imgSlider1}
                            className="w-full pb-6"
                            alt="Slide 1"
                        />
                        <div className="flex flex-wrap justify-center gap-6 px-8">
                            <h3 className="text-2xl font-bold text-white text-center">
                                Connect Your Projects to a More Efficient Future
                            </h3>
                            <span className="text-m text-white text-center">
                                Harness predictive metrics to slash energy consumption, turbocharge production, and fine-tune operational costs in real-time.
                            </span>
                        </div>
                    </div>

                    {/* Slide 2 */}
                    <div className="relative w-full pt-28">
                        <img
                            src={imgSlider2}
                            className="w-full pb-6"
                            alt="Slide 2"
                        />
                        <div className="flex flex-wrap justify-center gap-6 px-8">
                            <h3 className="text-2xl font-bold text-white text-center">
                                Boost your efficiency with Digital Twin
                            </h3>
                            <span className="text-m text-white text-center">
                                Unlock the doors to innovation: Dive into how Digital Twin revolutionizes project and construction management, optimizing resources and accelerating outcomes.
                            </span>
                        </div>
                    </div>

                    {/* Slide 3 */}
                    <div className="relative w-full pt-28">
                        <img
                            src={imgSlider3}
                            className="w-full pb-6"
                            alt="Slide 3"
                        />
                        <div className="flex flex-wrap justify-center gap-6 px-8">
                            <h3 className="text-2xl font-bold text-white text-center">
                                MEP/FP Optimization
                            </h3>
                            <span className="text-m text-white text-center">
                                Unlock a 25% labor cost savings while enhancing productivity and efficiency at every stage of your projects
                            </span>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default Slider2
