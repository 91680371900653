import React, {  useContext,useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { ModelContext } from '../context/ModelContext'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    Avatar,
} from '@chatscope/chat-ui-kit-react'
import img from '../img/logovoy.png'

const ChatComponent = ({ propsFromModel }) => {


    const [messages, setMessages] = useState([
     
    ])
    const [isTyping, setIsTyping] = useState(false)
    const [context, setContext] = useState(null)
    const { setData } = useContext(ModelContext)

    useEffect(() => {
        // Verificar si propsFromModel contiene elementos
        if (propsFromModel && propsFromModel.length > 0) {
            setContext(null);
            // console.clear();
            console.log('contexto reseteado');
            
            // Crear una matriz de instrucciones para cada elemento
            const instructions = propsFromModel.map((element) => {
                const elementID = element.ID; 
                return `Always respond using the properties of this element to answer what was asked of you, and always provide the ID in the following format: ID ${elementID}.`;
            });
            // Agregar las instrucciones a propsFromModel
            propsFromModel.forEach((element, index) => {
                element.instructions = instructions[index];
            });
    
            // Imprimir propsFromModel en la consola para verificar
            console.log(propsFromModel);
            setContext(propsFromModel);

        }
    }, [propsFromModel]);
    

    const handleSendRequest = async (message) => {
        const newMessage = {
            message,
            direction: 'outgoing',
            sender: 'user',
        }

        setMessages((prevMessages) => [...prevMessages, newMessage])
        setIsTyping(true)

        const requestBody = {
            chatMessages: [...messages, newMessage],
            context: context,
        }

        console.log('Request body being sent:', requestBody)
        try {
            const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/chat`

            const response = await axios.post(apiUrl, requestBody)
            const content = response.data.choices[0]?.message?.content
            console.log(content)

            if (content) {
                const chatGPTResponse = {
                    message: content,
                    sender: 'ChatGPT',
                }
                setMessages((prevMessages) => [
                    ...prevMessages,
                    chatGPTResponse,
                ])
            }
        } catch (error) {
            console.error('Error processing message:', error)
        } finally {
            setIsTyping(false)
        }
    }


    

    const handleNumberClick = useCallback((event) => {
        const clickedText = event.target.textContent;
        console.log('Texto clicado:', clickedText);
    
        // Utilizar una expresión regular para encontrar el número después de "ID"
        const match = clickedText.match(/ID (\d+)/);
    
        if (match) {
            const clickedNumber = parseInt(match[1], 10); // Extraer el número coincidente y convertirlo en entero
            console.log('Número extraído:', clickedNumber);
    
            // Crear un objeto con la propiedad 'dbId' y el número como valor
            const dbIdObject = { dbId: clickedNumber };
    
            // Crear un array con el objeto dbIdObject
            const dbIdArray = [dbIdObject];
    
            console.log('Array de dbId:', dbIdArray);
            
            // Verificar si dbIdArray no es indefinido
            if (dbIdArray !== undefined) {
                setData([], 'coloredIds');
                setData(dbIdArray, 'coloredIds');
            }
        }
    }, []);
    

    useEffect(() => {
        // Agregar el manejador de clics en números después de que el componente se monte
        const chatContainer = document.querySelector('.chat-container')
        if (chatContainer) {
            chatContainer.addEventListener('click', handleNumberClick)
        }

        return () => {
            // Eliminar el manejador de clics en números al desmontar el componente
            if (chatContainer) {
                chatContainer.removeEventListener('click', handleNumberClick)
            }
        }
    }, [handleNumberClick])



    return (
        <div>
            <div className="w-full text-sm text-gray-900 h-96 dark:bg-gray-800 overflow-y-auto">
                <MainContainer className="dark:border-gray-800">
                    <ChatContainer className="dark:border-gray-800">
                        <MessageList
                            className="dark:bg-gray-600 dark:border-gray-800 chat-container"
                            scrollBehavior="smooth"
                            typingIndicator={
                                isTyping ? (
                                    <TypingIndicator
                                        className="bg-gray-700"
                                        content="ChatGPT is typing"
                                    />
                                ) : null
                            }
                        >
                            {messages.map((message, i) => {
                                const isChatGPT = message.sender === 'ChatGPT'

                                return (
                                    <Message
                                        key={i}
                                        model={message}
                                        className="dark:bg-gray-600 flex  dark:border-gray-800"
                                    >
                                        {isChatGPT && (
                                            <Avatar
                                                src={img}
                                                name="ChatGPT"
                                                size="sm"
                                            />
                                        )}
                                    </Message>
                                )
                            })}
                        </MessageList>
                        <MessageInput
                            className="bg-gray-700"
                            placeholder="Send a Message"
                            onSend={handleSendRequest}
                            attachButton={false}
                        />
                    </ChatContainer>
                </MainContainer>
            </div>
        </div>
    )
}

export default ChatComponent
