import React, { useEffect, useState, useContext } from 'react'
import { useGetCompanyID } from '../hooks/useGetCompanyID'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import Model from './home-model-div'
import Search from '../function/Search'
import WrappedMap from '../function/Map' // Importa el componente de mapa
import { ModelContext } from '../context/ModelContext'
import { ClipLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'
import GeneralState from '../components/tiles/GeneralState'
import ProjectCount from '../components/tiles/ProjectCount'
import ElectricalState from '../components/tiles/ElectricalState'
import EdilicialState from '../components/tiles/EdilicialState'
import StructuralState from '../components/tiles/StructuralState'
import WaterState from '../components/tiles/WaterState'
import NAVflotante from '../components/NAVflotante'
import { Legend } from '@tremor/react'

export const Home = () => {
    // Contexto y estados
    const { setData, selectedModel } = useContext(ModelContext)
    const [models, setModels] = useState([]) // Almacena modelos
    const [layoutTiles, setLayoutTiles] = useState({}) // Almacena datos de diseño
    const [GeneralStateTiles, setGeneralStateTiles] = useState([]) // Almacena datos de estados para los tiles
    const [chartSide, setChartSide] = useState([])
    const [tilesSide, setTilesSide] = useState([])

    const [loading, setLoading] = useState(true) // Estado de carga
    const { t } = useTranslation() // Para traducciones
    const [cookies, _] = useCookies(['access_token']) // Cookies para el token de acceso
    const companyID = useGetCompanyID() // Obtener ID de compañía
    const [selectedMapCategories, setSelectedMapCategories] = useState(
        new Set()
    ) // Categorías seleccionadas para el mapa
    const [searchTerm, setSearchTerm] = useState('') // Término de búsqueda
    const [numberOfModels, setNumberOfModels] = useState(0) // Número de modelos de la compañía
    const [selectedCategories, setSelectedCategories] = useState([]) // Categorías seleccionadas
    const [selectedCategoryStatus, setSelectedCategoryStatus] = useState({})

    // const [textIndex, setTextIndex] = useState(0)
    // const phrases = [
    //     'Trayendo los datos de la base de datos',
    //     'Cargando...',
    //     'Espere un momento...',
    // ]
    const [zones, setZones] = useState(null) // Término de búsqueda

    // Función para alternar la selección de una categoría
    const toggleCategory = (category) => {
        const updatedCategories = new Set(selectedCategories)
        const updatedMapCategories = new Set(selectedMapCategories)
        if (updatedCategories.has(category)) {
            updatedCategories.delete(category)
            updatedMapCategories.delete(category)
        } else {
            updatedCategories.add(category)
            updatedMapCategories.add(category)
        }

        setSelectedCategories(Array.from(updatedCategories))
        setSelectedMapCategories(updatedMapCategories)
    }
    // URL de la API de Google Maps
    const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    // Maneja cambios en el término de búsqueda
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }
    // Efecto de carga inicial y actualización de modelos al cambiar el término de búsqueda
    useEffect(() => {
        // Si hay un modelo seleccionado, recarga la página
        if (selectedModel) {
            window.location.reload()
        }
        const fetchModels = async () => {
            try {
                setLoading(true)
                // Realiza la solicitud para verificar datos del mes actual en función del companyID
                const verificarDatosMesActual = async () => {
                    try {
                        const response = await axios.get(
                            `${process.env.REACT_APP_BACKEND_BASE_URL}/dash/getUpdate/${companyID}`,
                            {
                                headers: {
                                    Authorization: cookies.access_token,
                                },
                            }
                        )
                        setGeneralStateTiles(response.data)
                    } catch (error) {
                        console.error(
                            'Error al verificar datos del mes actual:',
                            error
                        )
                    }
                }

                verificarDatosMesActual()

                const fetchingZones = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/zone/getZones?company=${companyID}`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )

                setZones(fetchingZones.data)

                const fetchingLayoutTiles = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/companies/getLayout/${companyID}`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )
                setLayoutTiles(fetchingLayoutTiles.data)

                // Obtiene modelos desde la API
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/models/${companyID}`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )
                // Filtra modelos según el término de búsqueda
                const filteredModels = response.data.filter((model) => {
                    return model.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                })
                setModels(filteredModels)
                setNumberOfModels(filteredModels.length)
                setLoading(false)
                // Obtiene datos de diseño de la compañía desde la API
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }

        fetchModels()
    }, [searchTerm])

    useEffect(() => {
        if (layoutTiles.layout) {
            const components = {
                projectCount: (
                    <ProjectCount
                        numProjects={numberOfModels}
                        companyID={companyID}
                    />
                ),
                GeneralState: (
                    <GeneralState
                        data={GeneralStateTiles}
                        category={selectedCategories}
                    />
                ),

                ElectricalState: (
                    <ElectricalState
                        data={GeneralStateTiles}
                        category={selectedCategories}
                    />
                ),
                EdilicialState: (
                    <EdilicialState
                        data={GeneralStateTiles}
                        category={selectedCategories}
                    />
                ),
                StructuralState: (
                    <StructuralState
                        data={GeneralStateTiles}
                        category={selectedCategories}
                    />
                ),
                WaterState: (
                    <WaterState
                        data={GeneralStateTiles}
                        category={selectedCategories}
                    />
                ),
            }
            setChartSide(
                layoutTiles.layout.chartSide.map((element) => {
                    return components[element]
                })
            )
            setTilesSide(
                layoutTiles.layout.tilesSide.map((element) => {
                    return components[element]
                })
            )
        }
    }, [layoutTiles, selectedCategories, numberOfModels])

    console.log(selectedCategories)
    console.log(zones)
    console.log(models)
    // console.log('Los modelos son ' + numberOfModels)
    // console.log('el layout para mostrar las tiles son :')
    // console.log(layoutTiles)
    // console.log(
    //     'Al componente de tile GeneralState.js le paso como props (todas las que halla)'
    // )
    // console.log(GeneralStateTiles)

    return (
        <div className="min-h-screen overflow-hidden flex space-x-0">
            <div className="hidden md:block w-24 xl:w-20 m-1 ">
                <div className=" bg-gray-50 dark:bg-slate-900 ">
                    <NAVflotante />
                </div>
            </div>
            <div className="w-full p-4 sm:p-12">
                <div>
                    <h2 className="text-2xl font-bold mb-2 text-center dark:text-white text-[#493E98] m-1">
                        {t('assetsDataInsight')}
                    </h2>
                    <div className="flex flex-col md:flex-col sm:w-full   xl:flex-row  p-4  sm:m-4">
                        <div className="flex flex-col items-center justify-center w-full md:w-full sm:w-full">
                            {!layoutTiles.length > 0 ? (
                                <>
                                    {chartSide.length > 0 && (
                                        <div className="sm:flex md:flex-row w-full sm:flex-col">
                                            {chartSide}
                                        </div>
                                    )}
                                    {tilesSide.length > 0 && (
                                        <div className="sm:flex md:flex-row w-full sm:flex-col">
                                            {tilesSide}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <ClipLoader
                                    size={50}
                                    color="#123abc"
                                    loading={true}
                                />
                            )}
                        </div>
                        <div className="flex items-center justify-center  md:w-full sm:w-full">
                            <div className="h-96 w-full">
                                <WrappedMap
                                    googleMapURL={googleMapURL}
                                    models={models}
                                    selectedMapCategories={
                                        selectedMapCategories
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full ">
                    <div className="flex flex-col sm:flex-row">
                        <div className="w-full flex flex-col md:flex-row">
                            <div className="flex flex-col w-full md:w-1/2 ">
                                <div className="justify-center items-center flex">
                                    <div className="w-full  md:w-86 xl:w-96">
                                        <Search
                                            searchTerm={searchTerm}
                                            onSearchChange={handleSearchChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-center ">
                                    {/* Etiquetas de categoría */}
                                    {zones &&
                                        zones.map((zona) => (
                                            <span
                                                key={zona._id}
                                                className={`text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full cursor-pointer dark:bg-gray-900 dark:text-blue-400 border border-gray-600 dark:border-gray-900 ${
                                                    selectedCategories.includes(
                                                        zona._id
                                                    )
                                                        ? 'bg-red-500 dark:bg-red-500 text-white dark:text-white'
                                                        : 'bg-red-50 text-black-900'
                                                }`}
                                                onClick={() =>
                                                    toggleCategory(zona._id)
                                                }
                                            >
                                                {zona.nameZone}
                                            </span>
                                        ))}
                                </div>
                            </div>
                            <div className="w-full md:w-1/2">
                                <div className="w-full flex justify-center md:justify-end">
                                    <Legend
                                        categories={[
                                            'Excellent',
                                            'Good',
                                            'Regular',
                                            'Bad',
                                        ]}
                                        colors={[
                                            'emerald',
                                            'orange',
                                            'yellow',
                                            'red',
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {!loading ? (
                        <div className="w-full  mt-4 ">
                            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 gap-4 p-1 ">
                                {models
                                    .filter((model) => {
                                        // Filtra los modelos basados en las categorías seleccionadas
                                        if (selectedCategories.length === 0) {
                                            // Si no hay categorías seleccionadas, muestra todos los modelos
                                            return true
                                        } else {
                                            // Si hay categorías seleccionadas, verifica si el modelo tiene una de las categorías
                                            return selectedCategories.includes(
                                                model.category
                                            )
                                        }
                                    })
                                    .map((model, index) => {
                                        // Busca el elemento en GeneralStateTiles que coincida con el modelID
                                        const generalStateData =
                                            GeneralStateTiles.find(
                                                (item) =>
                                                    item.modelId === model._id
                                            )
                                        return (
                                            <Link to={`/project/${model._id}`}>
                                                <div className="w-full ">
                                                    {/* Pasa el elemento correspondiente de GeneralStateTiles como prop */}
                                                    <Model
                                                        model={model}
                                                        generalStateData={
                                                            generalStateData
                                                        }
                                                    />
                                                </div>
                                            </Link>
                                        )
                                    })}
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col justify-center items-center mt-8">
                            <ClipLoader
                                color="#4B5A65"
                                loading={loading}
                                size={50}
                            />
                            <div className="text-xl mt-4">
                                {/* {phrases[textIndex]} */}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
