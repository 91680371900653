import React from 'react'
import toast from 'react-hot-toast'

import { getAllProperties } from '../function/forgeviewer'
const { Autodesk } = window

function getGeometryNode(nodeList, currentNode) {
    if (currentNode.data.type === 'geometry') {
        nodeList.push(currentNode)
    } else {
        currentNode.children.map((child) => {
            getGeometryNode(nodeList, child)
        })
    }
}

const runtime = {
    options: null,
    ready: null,
}

function initializeViewerRuntime(options) {
    if (!runtime.ready) {
        runtime.options = { ...options }
        runtime.ready = new Promise((resolve) =>
            Autodesk.Viewing.Initializer(runtime.options, resolve)
        )
    } else {
        if (
            ['accessToken', 'getAccessToken', 'env', 'api', 'language'].some(
                (prop) => options[prop] !== runtime.options[prop]
            )
        ) {
            return Promise.reject(
                'Cannot initialize another viewer runtime with different settings.'
            )
        }
    }
    return runtime.ready
}

export class Viewer extends React.Component {
    constructor(props) {
        super(props)
        this.container = null
        this.viewer = null
        this.nodeList = []
    }
    render() {
        return <div ref={(ref) => (this.container = ref)}></div>
    }

    componentDidMount() {
        initializeViewerRuntime(this.props.runtime || {})
            .then((_) => {
                this.viewer = new Autodesk.Viewing.GuiViewer3D(this.container, {
                    extensions: [
                        'Autodesk.DocumentBrowser',
                        'Autodesk.Hyperlink',
                        'Autodesk.LayerManager',
                        'Autodesk.BIM360.Minimap',
                        'Autodesk.Viewing.Popout',
                        'Autodesk.VisualClusters',
                        'SummaryExtension',
                        'Autodesk.ADN.ModelStructurePanel',
                        // 'GoogleMapsExtension',
                    ],
                })
                this.viewer.start()

                this.viewer.addEventListener(
                    Autodesk.Viewing.SELECTION_CHANGED_EVENT,
                    this.onViewerSelectionChange
                )
                this.viewer.addEventListener(
                    Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                    this.onViewChange
                )
                this.updateViewerState()

                //componente cargado correctamente?
            })
            .catch((err) => {
                console.error(err)
                // Puedes agregar una variable para saber si ya has intentado recargar y prevenir un bucle infinito
                if (!window.reloadAttempted) {
                    window.reloadAttempted = true
                    window.location.reload()
                } else {
                    toast.error('ERROR')
                    toast.error('Go back to home and reload the model')
                    console.error(err)
                }
            })
    }

    componentWillUnmount() {
        if (this.viewer) {
            this.viewer.finish()
            this.viewer = null
        }
    }
    // modificar el boton para quitar y poner colores. definir contexto y en cambios resetearlo desde adentro
    componentDidUpdate(prevProps) {
        if (this.viewer) {
            this.updateViewerState(prevProps)
        }
    }

    updateViewerState(prevProps) {
        if (
            !prevProps ||
            ((this.props.urn && this.props.urn !== prevProps.urn) || (this.props.selectedIndex &&this.props.selectedIndex !== prevProps.selectedIndex))
        ) {
            Autodesk.Viewing.Document.load(
                this.props.urn,
                (doc) => {
                    this.nodeList = []
                    const rootNode = doc.getRoot()
                    getGeometryNode(this.nodeList, rootNode)
                    return this.viewer
                        .loadDocumentNode(
                            doc,
                            this.nodeList[this.props.selectedIndex].getDefaultGeometry(true)
                        )
                        .then((i) => {
                            getAllProperties(this.viewer, this.props.setData)
                        })
                },
                (code, message, errors) => {
                    console.log(code, message, errors)
                    console.log(code)
                    console.log(message)
                    console.log(errors)
                    toast.error('ERROR loading model')
                    toast.error('Go back and reload the page')
                }
            )

            if (this.viewer.model) {
                this.viewer.model.clearThemingColors()
            }
        } else if (!this.props.urn && this.viewer.model) {
            this.viewer.unloadModel(this.viewer.model)
        }

        // const selectedIds = this.viewer.getSelection()
        // if (
        //     JSON.stringify(this.props.selectedIds || []) !==
        //     JSON.stringify(selectedIds)
        // ) {
        //     // this.viewer.select(this.props.selectedIds)
        // }

        if (
            !prevProps ||
            (this.props.coloredIds &&
                this.props.coloredIds !== prevProps.coloredIds)
        ) {
            const colorExtension = this.viewer.getExtension(
                'Autodesk.ADN.ModelStructurePanel'
            )
            if (colorExtension) {
                colorExtension.colorByState(this.props.coloredIds)
            }
        }

        
    }

    onViewerSelectionChange = () => {
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange({
                viewer: this.viewer,
                ids: this.viewer.getSelection(),
            })
        }
    }

    onViewChange = () => {
        getAllProperties(this.viewer, this.props.setData)
        if (this.props.onViewInitialized) {
            this.props.onViewInitialized({ nodes: this.nodeList })
        }
    }
}
