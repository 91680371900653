import { lazy, useEffect, useState } from 'react'
import Client from '../function/token'
import { Viewer } from '../pages/viewer'
import { useContext } from 'react'
import { ModelContext } from '../context/ModelContext'
import toast, { Toaster } from 'react-hot-toast'

const dynamicPanel = (company, panel) =>
    lazy(() => import(`./${company}/${panel}`))

export default function ViewerPage({ urn , selectedIndex , version}) {
    const [token, setToken] = useState('')
    const { setData, coloredIds, selectedModel, nodeList } = useContext(ModelContext)
    const [leftSide, setLeftSide] = useState(<></>)
    const [rightSide, setRightSide] = useState(<></>)

    useEffect(()=>{console.log(selectedIndex)},[selectedIndex])

    useEffect(() => {
        if (selectedModel) {
            console.log(selectedModel)
            const company = selectedModel.company
            const layout = selectedModel.layout
            const loadSide = async (side) => {
                const Comp = await dynamicPanel(company, layout[side])
                if (side === 'leftSide') {
                    setLeftSide(<Comp />)
                } else {
                    setRightSide(<Comp />)
                }
            }

            // Left side
            try {
                loadSide('leftSide')
            } catch (error) {}

            // Right side
            try {
                loadSide('rightSide')
            } catch (error) {}
        }
    }, [selectedModel])

    // No puedo crear tantos acces tokens. Creo 1 y lo persisto
    useEffect(() => {
        const accessToken = localStorage.getItem('token')

        // Comprobar explícitamente que el accessToken no sea null ni undefined
        if (
            accessToken !== null &&
            accessToken !== undefined &&
            accessToken !== 'null' &&
            accessToken !== 'undefined'
        ) {
            Client.getAccesstoken().then((token_data) => {
                console.log(token_data)
                setToken(token_data.access_token)
                setData(token_data.access_token, 'forgeToken')
            })
        }
    }, [setToken])

console.log(urn)

    return (
        <>
            <Toaster />
            <div className=" md:p-6 lg:pt-0 lg:pb-2 md:my-4 mx-4 sm:mx-4  lg:mx-1 ">
                <div className="flex flex-col lg:flex-row">
                    {/* Left Side */}
                    <div className="relative flex-grow lg:flex-grow-0 lg:w-72 p-1 max-w-sm		">
                        <div className="bg-[#e5e7eb] dark:bg-gray-700  rounded-lg shadow-xl h-full">
                            {leftSide}
                        </div>
                    </div>

                    {/* Middle */}
                    <div className="relative flex-grow p-1 pl-2 pr-2 rounded-lg min-h-max">
                        {/* <button
                            onClick={() => {
                                console.log(nodeList)
                            }}
                        >
                            Hola
                        </button> */}
                        <div className="w-full h-full min-h-[670px] rounded-lg relative flex flex-col">
                            {token !== '' ? (
                                <Viewer
                                    urn={
                                        urn
                                            ? `urn:${btoa(urn)
                                                  .replace('/', '_')
                                                  .replace('=', '')}`
                                            : ''
                                    }
                                    selectedIndex = {selectedIndex}
                                    runtime={{ accessToken: token }}
                                    setData={setData}
                                    onSelectionChange={({ viewer, ids }) => {
                                        let allProperties = [] // Array para acumular las propiedades de todos los ids
                                        setData([], 'resetProps') // Reseteo previo de las propiedades

                                        // Utiliza Promise.all para asegurar que todas las propiedades se recopilan antes de llamar a setData
                                        Promise.all(
                                            ids.map(
                                                (id) =>
                                                    new Promise(
                                                        (resolve, reject) => {
                                                            viewer.getProperties(
                                                                id,
                                                                (
                                                                    properties
                                                                ) => {
                                                                    allProperties.push(
                                                                        properties
                                                                    ) // Acumula las propiedades en el array
                                                                    resolve()
                                                                },
                                                                () => reject() // Manejo de errores
                                                            )
                                                        }
                                                    )
                                            )
                                        ).then(() => {
                                            setData(
                                                allProperties,
                                                'selectionProps'
                                            ) // Pasa todas las propiedades acumuladas
                                        })
                                    }}
                                    coloredIds={coloredIds}
                                    onViewInitialized={(nodeListViews) => {
                                        setData(nodeListViews.nodes,"nodeList")
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    {/* Right Side */}
                    <div className="relative flex-grow lg:flex-grow-0 w-full lg:w-60 p-1">
                        <div className="bg-[#e5e7eb] dark:bg-gray-700 rounded-lg shadow-xl h-full">
                            {rightSide}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
