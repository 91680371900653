import React, { useState, useEffect } from 'react'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps'
import axios from 'axios'
import {
    PhoneIcon,
    StarIcon,
    ClockIcon,
    CheckCircleIcon,
    UsersIcon,
} from '@heroicons/react/solid'

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react'

// Estilos para el mapa
const styles = {
    default: [],
    silver: [
        {
            elementType: 'geometry',
            stylers: [{ color: '#f5f5f5' }],
        },
        {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [{ color: '#616161' }],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#f5f5f5' }],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#bdbdbd' }],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#eeeeee' }],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#757575' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#e5e5e5' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#757575' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#dadada' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#616161' }],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#e5e5e5' }],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#eeeeee' }],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#c9c9c9' }],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }],
        },
    ],
    night: [
        { elementType: 'geometry', stylers: [{ color: '#111827' }] },
        {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#242f3e' }],
        },
        { elementType: 'labels.text.fill', stylers: [{ color: '#D9DBDD' }] },
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
        },
        {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
        },
        {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
        },
        {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
        },
    ],
    retro: [
        { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
        {
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#f5f1e6' }],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#c9b2a6' }],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#dcd2be' }],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#ae9e90' }],
        },
        {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#93817c' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#a5b076' }],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#447530' }],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#f5f1e6' }],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#fdfcf8' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#f8c967' }],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#e9bc62' }],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{ color: '#e98d58' }],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#db8555' }],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#806b63' }],
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
        },
        {
            featureType: 'transit.line',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8f7d77' }],
        },
        {
            featureType: 'transit.line',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#ebe3cd' }],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ color: '#b9d3c2' }],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#92998d' }],
        },
    ],
    dark101: [
        {
            elementType: 'geometry',
            stylers: [
                {
                    color: '#1d2c4d',
                },
            ],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#8ec3b9',
                },
            ],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#1a3646',
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#4b6878',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#64779e',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#4b6878',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#334e87',
                },
            ],
        },
        {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#023e58',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#283d6a',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#6f9ba5',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#1d2c4d',
                },
            ],
        },
        {
            featureType: 'poi.business',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#023e58',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#3C7680',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#304a7d',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#98a5be',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#1d2c4d',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#2c6675',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#255763',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#b0d5ce',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#023e58',
                },
            ],
        },
        {
            featureType: 'road.local',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#98a5be',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#1d2c4d',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#283d6a',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#3a4762',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#0e1626',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#4e6d70',
                },
            ],
        },
    ],
    darkdark101: [
        {
            elementType: 'geometry',
            stylers: [
                {
                    color: '#212121',
                },
            ],
        },
        {
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#757575',
                },
            ],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#212121',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#757575',
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#9e9e9e',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#bdbdbd',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#757575',
                },
            ],
        },
        {
            featureType: 'poi.business',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#181818',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#616161',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#1b1b1b',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#2c2c2c',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#8a8a8a',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#373737',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#3c3c3c',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#4e4e4e',
                },
            ],
        },
        {
            featureType: 'road.local',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#616161',
                },
            ],
        },
        {
            featureType: 'transit',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#757575',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#000000',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#3d3d3d',
                },
            ],
        },
    ],

    hiding: [
        {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
        },
    ],
}

// Componente GoogleMapComponent con modo noche
const GoogleMapComponent = withScriptjs(
    withGoogleMap((props) => {
        const selectedStyle = props.isDarkMode ? styles.night : styles.silver

        return (
            <GoogleMap
                defaultZoom={14}
                defaultCenter={{ lat: props.lat, lng: props.lng }}
                options={{
                    styles: selectedStyle,
                }}
            >
                <Marker position={{ lat: props.lat, lng: props.lng }} />
            </GoogleMap>
        )
    })
)

// Componente principal PlaceDetails
const PlaceDetails = ({ value }) => {
    const [place, setPlaceDetails] = useState(null)
    const [error, setError] = useState(null)
    const [isDarkMode, setIsDarkMode] = useState(false)

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia(
            '(prefers-color-scheme: dark)'
        )

        const handleDarkModeChange = (event) => {
            setIsDarkMode(event.matches)
        }

        darkModeMediaQuery.addEventListener('change', handleDarkModeChange)
        setIsDarkMode(darkModeMediaQuery.matches)

        return () => {
            darkModeMediaQuery.removeEventListener(
                'change',
                handleDarkModeChange
            )
        }
    }, [])

    useEffect(() => {
        const fetchPlaceDetails = async () => {
            try {
                const response = await axios.get(
                    `https://places.googleapis.com/v1/places/${value.address.place_id}?fields=currentOpeningHours,reviews,rating,userRatingCount,internationalPhoneNumber,accessibilityOptions&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                )
                setPlaceDetails(response.data)
            } catch (err) {
                setError(err.message)
            }
        }

        if (value && value.address && value.address.place_id) {
            fetchPlaceDetails()
        }
    }, [value])

    const renderStars = (rating) => {
        const stars = []
        for (let i = 0; i < rating; i++) {
            stars.push(
                <StarIcon
                    key={i}
                    className="h-6 w-6 text-yellow-400 dark:text-yellow-200 fill-current"
                />
            )
        }
        return stars
    }

    function getDayName(day) {
        const daysOfWeek = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ]
        return daysOfWeek[day - 1]
    }

    function formatTime(time) {
        const options = { hour: 'numeric', minute: '2-digit', hour12: true }
        const date = new Date(
            time.date.year,
            time.date.month - 1,
            time.date.day,
            time.hour,
            time.minute
        )
        return date.toLocaleTimeString(undefined, options)
    }

    console.log(place)
    return (
      <div className="flex-1 md:mr-2">
      <div className="w-full flex flex-col md:flex-row"> {/* Cambio de flex-row a flex-col en pantallas pequeñas */}
          <div className="w-full md:w-1/3 bg-slate-100 dark:bg-[#17263C] order-first md:order-last"> {/* Cambio de orden en pantallas pequeñas */}
               <TabGroup>
                        <TabList>
                            <Tab icon={UsersIcon}>Google Stats</Tab>
                            <Tab icon={StarIcon}>Google reviews</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <div>
                                    <div className="place-details flex flex-col p-3">
                                        <div className="place-info ">
                                            {/* Rating Item */}
                                            <div className="info-item  flex flex-col items-center justify-center">
                                                <div className="flex flex-row">
                                                    {' '}
                                                    {renderStars(place?.rating)}
                                                </div>
                                                <span className="text-gray-600 dark:text-white ">
                                                    {place?.rating
                                                        ? place.rating.toFixed(
                                                              1
                                                          )
                                                        : 'No rating'}
                                                </span>
                                            </div>
                                            {/* Opening Hours Item */}
                                            <div
                                                className={`info-item mb-1 flex ${
                                                    place?.currentOpeningHours
                                                        ?.openNow
                                                        ? 'text-green-400 dark:text-green-200'
                                                        : 'text-red-400 dark:text-red-200'
                                                }`}
                                            >
                                                <ClockIcon className="icon h-6 w-6 text-gray-600 dark:text-white mr-2" />
                                                <span className="text-gray-600 dark:text-white">
                                                    {place?.currentOpeningHours
                                                        ?.openNow
                                                        ? 'Open now'
                                                        : 'Closed'}
                                                </span>
                                            </div>
                                            {/* Accessibility Item */}
                                            <div className="info-item mb-1 flex items-center justify-center">
                                                <CheckCircleIcon
                                                    className={`icon h-6 w-6 ${
                                                        place
                                                            ?.accessibilityOptions
                                                            ?.wheelchairAccessibleEntrance ===
                                                        'Yes'
                                                            ? 'text-green-400 dark:text-green-200'
                                                            : 'text-red-400 dark:text-red-200'
                                                    } mr-2`}
                                                />
                                                <span
                                                    className={`text-gray-600 dark:text-white flex-1 ${
                                                        place
                                                            ?.accessibilityOptions
                                                            ?.wheelchairAccessibleEntrance ===
                                                        'No'
                                                            ? 'text-red-400 dark:text-red-200'
                                                            : ''
                                                    }`}
                                                >
                                                    Wheelchair Accessible:{' '}
                                                    {place?.accessibilityOptions
                                                        ?.wheelchairAccessibleEntrance
                                                        ? 'Yes'
                                                        : 'No'}
                                                </span>
                                            </div>
                                            {/* Wheelchair Accessible Restroom Item */}
                                            <div className="info-item mb-1 flex items-center justify-center">
                                                <CheckCircleIcon
                                                    className={`icon h-6 w-6 ${
                                                        place
                                                            ?.accessibilityOptions
                                                            ?.wheelchairAccessibleRestroom ===
                                                        'Yes'
                                                            ? 'text-green-400 dark:text-green-200'
                                                            : 'text-red-400 dark:text-red-200'
                                                    } mr-2`}
                                                />
                                                <span
                                                    className={`text-gray-600 dark:text-white flex-1 ${
                                                        place
                                                            ?.accessibilityOptions
                                                            ?.wheelchairAccessibleRestroom ===
                                                        'No'
                                                            ? 'text-red-400 dark:text-red-200'
                                                            : ''
                                                    }`}
                                                >
                                                    Wheelchair Accessible
                                                    Restroom:{' '}
                                                    {place?.accessibilityOptions
                                                        ?.wheelchairAccessibleRestroom
                                                        ? 'Yes'
                                                        : 'No'}
                                                </span>
                                            </div>
                                            {/* Phone Number Item */}
                                            {place?.internationalPhoneNumber && (
                                                <div className="info-item mb-1 flex items-center ">
                                                    <PhoneIcon className="icon h-6 w-6 text-gray-900 dark:text-white mr-2" />
                                                    <span className="text-gray-600 dark:text-white">
                                                        {
                                                            place?.internationalPhoneNumber
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {/* Opening Hours Table */}
                                            {place?.currentOpeningHours
                                                ?.periods && (
                                                <div className="info-item  flex flex-col">
                                                    <table className="w-full border-collapse border border-gray-300 dark:border-white">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-gray-600 dark:text-white text-center">
                                                                    Day
                                                                </th>
                                                                <th className="text-gray-600 dark:text-white text-center">
                                                                    Open
                                                                </th>
                                                                <th className="text-gray-600 dark:text-white text-center">
                                                                    Close
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {place?.currentOpeningHours?.periods.map(
                                                                (
                                                                    period,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="text-gray-600 dark:text-white"
                                                                    >
                                                                        <td className="text-center">
                                                                            {getDayName(
                                                                                period
                                                                                    .open
                                                                                    .day
                                                                            )}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {formatTime(
                                                                                period.open
                                                                            )}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {formatTime(
                                                                                period.close
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="p-4 max-h-72			 overflow-y-auto ">
                                    <h2 className="text-2xl font-semibold mb-4 dark:text-gray-400">
                                        Reseñas
                                    </h2>
                                    {place?.reviews?.map((review, index) => (
                                        <div key={index} className="mb-2">
                                            {review.originalText?.text && (
                                                <div className="flex items-center mb-1 dark:text-gray-200">
                                                    <img
                                                        src={
                                                            review
                                                                .authorAttribution
                                                                .photoUri
                                                        }
                                                        alt={
                                                            review
                                                                .authorAttribution
                                                                .displayName
                                                        }
                                                        className="w-10 h-10 rounded-full mr-2"
                                                    />
                                                    <div>
                                                        <p className="text-md font-semibold">
                                                            {
                                                                review
                                                                    .authorAttribution
                                                                    .displayName
                                                            }
                                                        </p>
                                                        <p className="text-gray-500 dark:text-white text-sm">
                                                            {new Date(
                                                                review.publishTime
                                                            ).toLocaleDateString(
                                                                'es-AR',
                                                                {
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: 'numeric',
                                                                }
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            {review.originalText?.text && (
                                                <div className="flex items-center mb-1 ">
                                                    <div className="text-yellow-500 mr-1">
                                                        {Array.from(
                                                            {
                                                                length: Math.floor(
                                                                    review.rating
                                                                ),
                                                            },
                                                            (_, i) => (
                                                                <span
                                                                    key={i}
                                                                    role="img"
                                                                    aria-label="star"
                                                                    className="text-yellow-500 dark:text-yellow-700"
                                                                >
                                                                    ★
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                    <p className="text-gray-600  dark:text-gray-400">
                                                        {review.rating.toFixed(
                                                            1
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                            {review.originalText?.text && (
                                                <p className="text-gray-700 dark:text-gray-300">
                                                    {review.originalText.text}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        </TabPanels>
                    </TabGroup>
                </div>

                <div className="w-full md:w-2/3 h-full">
                                      <GoogleMapComponent
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: '40vh' }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        lat={value.address.lat}
                        lng={value.address.lng}
                        isDarkMode={isDarkMode} // Pasa la información de isDarkMode al componente GoogleMapComponent
                    />
                </div>
            </div>
        </div>
    )
}

export default PlaceDetails
