import React, { useEffect, useState } from 'react'
import MAPSlogo from '../../img/MAPS-logo.png'
import {
    AreaChart,
    Card,
    Title,
    BadgeDelta,

} from '@tremor/react'
import axios from 'axios' // Importa Axios
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

const ComparationTab = ({ models }) => {
    const [selectedModel1, setSelectedModel1] = useState('')
    const [selectedModel2, setSelectedModel2] = useState('')
    const [selectedModelName1, setSelectedModelName1] = useState('')
    const [selectedModelName2, setSelectedModelName2] = useState('')
    const [model1Info, setModel1Info] = useState(null) // Estado para almacenar la información del modelo 1
    const [model2Info, setModel2Info] = useState(null) // Estado para almacenar la información del modelo 2
    const [cookies, _] = useCookies(['access_token'])
    const [chartData, setChartData] = useState([])
    const [category, setCategory] = useState('generalState')
    const [selectedYear, setSelectedYear] = useState(
        new Date().getFullYear().toString()
    )
    const { t } = useTranslation();

    const handleModel1Change = async (event) => {
        setSelectedModel1(event.target.value)
        const selectedModelData = models.find(
            (model) => model._id === event.target.value
        )
        if (selectedModelData) {
            console.log('Modelo seleccionado 1:', selectedModelData)
            setSelectedModelName1(selectedModelData.name)
            // Realiza la solicitud al endpoint con Axios
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/dash/getInfo/${event.target.value}`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )
                // Almacena la información en el estado
                setModel1Info(response.data)
            } catch (error) {
                console.error(
                    'Error al obtener la información del modelo 1:',
                    error
                )
            }
        }
    }

    const handleModel2Change = async (event) => {
        setSelectedModel2(event.target.value)
        const selectedModelData = models.find(
            (model) => model._id === event.target.value
        )
        if (selectedModelData) {
            console.log('Modelo seleccionado 2:', selectedModelData)
            setSelectedModelName2(selectedModelData.name)

            // Realiza la solicitud al endpoint con Axios
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/dash/getInfo/${event.target.value}`,
                    {
                        headers: { authorization: cookies.access_token },
                    }
                )
                // Almacena la información en el estado
                setModel2Info(response.data)
            } catch (error) {
                console.error(
                    'Error al obtener la información del modelo 2:',
                    error
                )
            }
        }
    }

    // Función para obtener el URL de Google Maps con el placeId desde address
    const getGoogleMapsUrl = (address) => {
        return `https://www.google.com/maps/place/?q=place_id:${address.place_id}`
    }

    // Función para determinar el color y el texto según el valor de variantState
    const getBadgeDelta = (variantState) => {
        let deltaType = 'unchanged'
        let text = 'Unchanged'

        if (typeof variantState !== 'undefined') {
            if (variantState > 5) {
                deltaType = 'increase'
                text = 'Increase'
            } else if (variantState > 0) {
                deltaType = 'moderateIncrease'
                text = 'Moderate Increase'
            } else if (variantState < -5) {
                deltaType = 'decrease'
                text = 'Decrease'
            } else if (variantState < 0) {
                deltaType = 'moderateDecrease'
                text = 'Moderate Decrease'
            }
        }

        return (
            <BadgeDelta deltaType={deltaType}>
                {text}{' '}
                {typeof variantState !== 'undefined'
                    ? `(${variantState.toFixed(2)}%)`
                    : ''}
            </BadgeDelta>
        )
    }

    // Función para determinar el color según el rango
    function getColor(value) {
        if (value < 60) {
            return 'text-red-500'
        } else if (value < 65) {
            return 'text-orange-600'
        } else if (value < 70) {
            return 'text-orange-300'
        } else if (value < 75) {
            return 'text-yellow-500'
        } else if (value < 80) {
            return 'text-green-300'
        } else if (value < 85) {
            return 'text-green-600'
        } else {
            return 'text-green-800'
        }
    }

    // Obtén los datos actuales del mes y año actual
    const currentYear = new Date().getFullYear().toString()
    const currentMonth = (new Date().getMonth() + 1).toString()
    const selectedModel1Data = models.find(
        (model) => model._id === selectedModel1
    )
    const selectedModel2Data = models.find(
        (model) => model._id === selectedModel2
    )

    // Maneja el cambio en el valor del select del año
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value)
    }

    // Actualiza la función getComparisonData para usar el año seleccionado
    const getComparisonData = (category) => {
        if (model1Info && model2Info) {
            // Convierte el año seleccionado de cadena a número
            const selectedYearInt = parseInt(selectedYear, 10)

            // Create an array of numbers from 1 to 12
            const months = Array.from({ length: 12 }, (_, index) => index + 1)

            // Get the data for months from model1Info and model2Info
            const data = months.map((month) => {
                const monthData1 =
                    model1Info[0]?.states[selectedYearInt]?.[month]
                const monthData2 =
                    model2Info[0]?.states[selectedYearInt]?.[month]

                return {
                    date: month,
                    [selectedModelName1]: monthData1
                        ? monthData1[category] * 100
                        : 0,
                    [selectedModelName2]: monthData2
                        ? monthData2[category] * 100
                        : 0,
                }
            })

            setChartData(data)
        }
    }

    useEffect(() => {
        getComparisonData(category)
    }, [model1Info, model2Info, category, selectedYear, ])

    const valueFormatter = function (number) {
        return number.toFixed(2) + '%'
    }
    const categories = [ //no hardcodear esto y que lo lea de layout en company
        'generalState',
        'EdilicialState',
        'ElectricalState',
        'WaterState',
    ]


    const [imageURL1, setImageURL1] = useState(""); // Estado para la URL de la imagen del modelo seleccionado
    const [imageURL2, setImageURL2] = useState(""); // Estado para la URL de la imagen del modelo seleccionado

    useEffect(() => {
        async function fetchImageURL() {
            try {
                if (selectedModel1 && selectedModel1Data && selectedModel1Data.thumbnail) {
                    // Obtener la URL de la imagen para el modelo seleccionado 1
                    const response1 = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/s3/get-image-url`, {
                        params: {
                            folderName: "thumbnails",
                            originalName: selectedModel1Data.thumbnail
                        }
                    });
                    setImageURL1(response1.data.url);
                }
    
                if (selectedModel2 && selectedModel2Data && selectedModel2Data.thumbnail) {
                    // Obtener la URL de la imagen para el modelo seleccionado 2
                    const response2 = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/s3/get-image-url`, {
                        params: {
                            folderName: "thumbnails",
                            originalName: selectedModel2Data.thumbnail
                        }
                    });
                    setImageURL2(response2.data.url);
                }
            } catch (error) {
                console.error("Error fetching image URL:", error);
            }
        }
        fetchImageURL();
    }, [selectedModel1, selectedModel2, selectedModel1Data, selectedModel2Data]);
    

    // Función para transformar los nombres
    function transformCategoryName(categoryName) {
        switch (categoryName) {
            case 'generalState':
                return 'General'
            case 'EdilicialState':
                return 'Building'
            case 'ElectricalState':
                return 'Electrical'
            case 'WaterState':
                return 'Sanitory'
            default:
                return categoryName
        }
    }

    console.log(model1Info)
    return (
        <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row ">
                <div className="mt-4 lg:mr-4 w-full lg:w-1/2">
                    <div className="flex justify-center">
                        <select
                            className="w-full p-2 border rounded bg-white dark:bg-gray-800 text-black dark:text-white"
                            value={selectedModel1}
                            onChange={handleModel1Change}
                        >
                            <option value="">{t('selectmodel1')}</option>
                            {models.map((model) => (
                                <option key={model._id} value={model._id}>
                                    {model.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {selectedModel1 && (
                        <div className=" flex flex-col lg:flex-row mt-2 border p-1 shadow-lg md:shadow-day dark:shadow-sky-700">
                            
                            <div className="flex flex-col justify-center items-center w-full lg:w-1/3 text-center dark:bg-gray-800 dark:text-white">
                                {model1Info &&
                                    model1Info[0]?.states?.[currentYear]?.[
                                        currentMonth
                                    ] && (
                                        <div>
                                            <div>
                                                <Title>Modelo 1</Title>
                                            </div>
                                            <p className="font-bold text-black text-xl">
                                                General:{' '}
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.generalState * 100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.generalState * 100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                                {t('edilicialText')}:{' '}
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EdilicialState *
                                                            100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EdilicialState *
                                                        100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                            {t('electricalText')}:{' '}                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.ElectricalState *
                                                            100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.ElectricalState *
                                                        100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                            {t('structuralText')}:{' '}                                                     
                                            <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EstructuralState *
                                                            100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EstructuralState *
                                                        100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                            {t('waterText')}:{' '}  
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.WaterState * 100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model1Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.WaterState * 100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                            </div>
                            <div className="w-full lg:w-2/3 content-center flex flex-col items-center">
                        <img
                            className="w-64 h-48 object-center object-cover p-1 rounded-xl"
                            src={imageURL1} // Usar la URL de la imagen aquí
                            alt="Imagen del modelo 1"
                        />

                                <a
                                    href={getGoogleMapsUrl(
                                        models.find(
                                            (model) =>
                                                model._id === selectedModel1
                                        )?.address || {}
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={MAPSlogo}
                                        className="object-contain object-center w-32"
                                        alt="Logo de Google Maps"
                                    />
                                </a>
                                {selectedModel1Data && (
                                    <div className="mt-2">
                                        {getBadgeDelta(
                                            selectedModel1Data.variantState
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-4 lg:ml-4 w-full lg:w-1/2">
                    <div className="flex justify-center">
                        <select
                            className="w-full p-2 border rounded bg-white dark:bg-gray-800 text-black dark:text-white"
                            value={selectedModel2}
                            onChange={handleModel2Change}
                        >
                            <option value="">   {t('selectmodel2')}</option>
                            {models.map((model) => (
                                <option key={model._id} value={model._id}>
                                    {model.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {selectedModel2 && (
                        <div className=" flex flex-col lg:flex-row mt-2 border p-1 shadow-lg md:shadow-day dark:shadow-sky-700">
                            <div className="flex flex-col justify-center items-center w-full lg:w-1/3 text-center  dark:bg-gray-800 dark:text-white ">
                                {model2Info &&
                                    model2Info[0]?.states?.[currentYear]?.[
                                        currentMonth
                                    ] && (
                                        <div>
                                            <div>
                                                <Title>Modelo 2</Title>
                                            </div>
                                            <p className="font-bold text-xl">
                                                General:{' '}
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.generalState * 100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.generalState * 100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                            {t('edilicialText')}:{' '}                                                     
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EdilicialState *
                                                            100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EdilicialState *
                                                        100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                            {t('electricalText')}:{' '}                                                     
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.ElectricalState *
                                                            100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.ElectricalState *
                                                        100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                            {t('structuralText')}:{' '}                                                     
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EstructuralState *
                                                            100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.EstructuralState *
                                                        100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                            <p className="font-bold">
                                            {t('waterText')}:{' '}                                                     
                                                <span
                                                    className={`h-full flex items-center justify-center ${getColor(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.WaterState * 100
                                                    )}`}
                                                >
                                                    {`${(
                                                        model2Info[0]?.states?.[
                                                            currentYear
                                                        ]?.[currentMonth]
                                                            ?.WaterState * 100
                                                    ).toFixed(0)}%`}
                                                </span>
                                            </p>
                                        </div>
                                    )}
                            </div>
                            <div className="w-full lg:w-2/3 content-center flex flex-col items-center ">
                        <img
                            className="w-64 h-48 object-center object-cover p-1 rounded-xl"
                            src={imageURL2} // Usar la URL de la imagen aquí
                            alt="Imagen del modelo 2"
                        />

                                <a
                                    href={getGoogleMapsUrl(
                                        models.find(
                                            (model) =>
                                                model._id === selectedModel2
                                        )?.address || {}
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={MAPSlogo}
                                        className="object-contain object-center w-32"
                                        alt="Logo de Google Maps"
                                    />
                                </a>
                                {selectedModel2Data && (
                                    <div className="mt-2">
                                        {getBadgeDelta(
                                            selectedModel2Data.variantState
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div className="flex flex-row  w-full  mt-4 ">
                    <div className="w-full  lg:mr-4">
                        <select
                            className="w-full p-2  border rounded bg-white dark:bg-gray-800 text-black dark:text-white"
                            value={category}
                            onChange={(event) =>
                                setCategory(event.target.value)
                            }
                        >
                            {categories.map((categoryOption, index) => (
                                <option key={index} value={categoryOption}>
                                    {transformCategoryName(categoryOption)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="w-full lg:ml-4">
                        <select
                            className="w-full p-2 border rounded bg-white dark:bg-gray-800 text-black dark:text-white"
                            value={selectedYear}
                            onChange={handleYearChange}
                        >
                            {/* Aquí puedes generar opciones para los años que desees */}
                            <option value="2026">2026</option>
                            <option value="2025">2025</option>
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                     
                            {/* Agrega más años según tus necesidades */}
                        </select>
                    </div>
                </div>
                <div className="mt-6 shadow-lg md:shadow-day dark:shadow-cyan-800	">
                <Card className="  mb-10 p-1">
                    {chartData && (
                        <AreaChart
                            className="h-72 mt-4"
                            data={chartData}
                            index="date"
                            categories={[
                                selectedModelName1,
                                selectedModelName2,
                            ]}
                            colors={['indigo', 'cyan']}
                            valueFormatter={valueFormatter}
                        />
                    )}
                </Card>
                </div>
            </div>
        </div>
    )
}

export default ComparationTab
