import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react';
import { useGetCompanyID } from '../hooks/useGetCompanyID';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import logoBlack from '../img/logoblack.png'
import logo from '../img/logo.png'
import LanguageSelector from '../components/LenguageSelector'

export const Header = () => {
    const [cookies, setCookies] = useCookies(['access_token'])
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const companyID = useGetCompanyID();

    const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar la visibilidad del menú

    const logoImageRef = useRef(null)
    const logoImageDarkRef = useRef(null)

    const isDarkModeEnabled = () => {
        return (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        )
    }

    const getThumbnail = async (companyID) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/companies/getThumbnail/${companyID}`,
                {
                    headers: { authorization: cookies.access_token },
                }
            );
            setThumbnailUrl(response.data.thumbnailUrl);
        } catch (error) {
            console.error('Error al obtener la thumbnail de la compañía:', error);
        }
    };

    const updateLogoVisibility = () => {
        const isDarkMode = isDarkModeEnabled()
        if (logoImageRef.current && logoImageDarkRef.current) {
            logoImageRef.current.style.display = isDarkMode ? 'none' : 'block'
            logoImageDarkRef.current.style.display = isDarkMode
                ? 'block'
                : 'none'
        }
    }

    useEffect(() => {
        updateLogoVisibility()
    }, [])

    useEffect(() => {
        if (companyID) {
            getThumbnail(companyID);
        }
    }, [companyID]);

    const logout = () => {
        setCookies('access_token', '')
        window.localStorage.clear()
        navigate('/')
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Alternar la visibilidad del menú
    }

    return (
        <div>
            <header>
                <nav className="px-4 lg:px-6 py-2.5">
                    <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                        <div
                            onClick={() => navigate('/home')}
                            className="flex items-center cursor-pointer"
                        >
                            {thumbnailUrl && (
                                <img
                                    id="thumbnailImage"
                                    className="mr-3 h-7 sm:h-10"
                                    src={thumbnailUrl}
                                    alt="Thumbnail de la compañía"
                                />
                            )}
                            <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"></span>
                        </div>

                        <div class="flex items-center lg:order-2">
                            <div class="mr-4">
                                <LanguageSelector />
                            </div>
                            <a
                               
                                class=" bg-[#493E98] hover:bg-[#6c8088] text-white py-2 px-4 rounded-full"
                            >
                                {!cookies.access_token ? (
                                    <Link to="/">Login</Link>
                                ) : (
                                    <button onClick={logout}>
                                        {' '}
                                        {t('logout')}{' '}
                                    </button>
                                )}
                            </a>
                            <button
                                onClick={toggleMenu}
                                type="button"
                                class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                aria-expanded={isMenuOpen ? 'true' : 'false'} // Agregar atributo aria-expanded
                            >
                                <span class="sr-only">Open main menu</span>
                                <svg
                                    class={`w-6 h-6 ${isMenuOpen ? 'hidden' : ''}`} // Ocultar el ícono cuando el menú está abierto
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M3 5a1 1 0 011-1h12 a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                <svg
                                    class={`w-6 h-6 ${isMenuOpen ? '' : 'hidden'}`} // Ocultar el ícono cuando el menú está cerrado
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </nav>
            </header>

            {/* Menú desplegable debajo del encabezado */}
            <div
                class={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`} // Mostrar u ocultar el menú según el estado
                id="mobile-menu-2"
            >
                <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 p-4 m-2">
                    <li>
                        <a
                            href="/home"
                            class="bg-[#493E98] lg:bg-transparent text-bg-[#493E98] lg:p-0 dark:text-white"
                            aria-current="page"
                        >
                            <a
                                href="/home"
                                className="flex items-center space-x-2"
                            >
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 shrink-0"><path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"></path></svg>
                                <p class="text-[#493E98] dark:text-white">
                                    PANEL
                                </p>
                            </a>
                        </a>
                    </li>
                    {/* Agrega más elementos de menú aquí */}
                    <li>
                        <a
                            href="/comparation"
                            class="bg-[#493E98] lg:bg-transparent text-bg-[#493E98] lg:p-0 dark:text-white"
                            aria-current="page"
                        >
                            <a
                                href="/comparation"
                                className="flex items-center space-x-2"
                            >
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"></path></svg>
                                <p class="text-[#493E98] dark:text-white">
                                    ANALYTICS
                                </p>
                            </a>
                        </a>
                    </li>
                    <li>
                        <a
                            // href="/comparation"
                            class="bg-[#493E98] lg:bg-transparent text-bg-[#493E98] lg:p-0 dark:text-white"
                            aria-current="page"
                        >
                            <a
                                // href="/comparation"
                                className="flex items-center space-x-2"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"></path></svg>
                                <p class="text-[#493E98] dark:text-white">
                                    UPDATE
                                </p>
                            </a>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
