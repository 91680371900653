import React, { useState, useEffect } from 'react';
import { Flex, AreaChart, Button, BadgeDelta } from "@tremor/react";
import Modal from 'react-modal';
import { ArrowNarrowRightIcon, XIcon } from "@heroicons/react/solid"; // Importa el ícono X


const GeneralStateChart = ({ data }) => {
    const [selectedYear, setSelectedYear] = useState('');
    const [yearlyAverages, setYearlyAverages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showChart, setShowChart] = useState(false); // Controla la visibilidad del gráfico

    useEffect(() => {
        // Filtrar los años con datos
        const yearsWithData = [...new Set(data.flatMap(item => Object.keys(item.tile)))];

        // Calcular el promedio del estado general para cada año en todos los meses disponibles
        const yearlyAverages = yearsWithData.map(year => {
            const monthlyAverages = Array(12).fill(0);
            const monthCounts = Array(12).fill(0);

            data.forEach(item => {
                const tileData = item.tile[year];
                if (tileData) {
                    Object.keys(tileData).forEach(month => {
                        const generalState = tileData[month].generalState;
                        monthlyAverages[parseInt(month) - 1] += generalState;
                        monthCounts[parseInt(month) - 1]++;
                    });
                }
            });

            // Calcular el promedio mensual
            return monthlyAverages.map((total, index) => ({
                year,
                month: index + 1,
                average: monthCounts[index] > 0 ? total / monthCounts[index] : 0
            }));
        });

        setYearlyAverages(yearlyAverages);
    }, [data]);


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const valueFormatter = (number) => (number * 100).toFixed(2) + '%';

    const yearsWithData = yearlyAverages.map((yearData) => yearData[0].year);
    const yearOptions = yearsWithData.map((year) => (
        <option key={year} value={year}>
            {year}
        </option>
    ));

    return (
        <div>
            <Flex className="border-t">
                <Button className='mt-1' size="xs" variant="light" icon={ArrowNarrowRightIcon} iconPosition="right" onClick={openModal}>
                    Chart
                </Button>
                <BadgeDelta className='mt-1' deltaType="moderateIncrease">23.1%</BadgeDelta>
            </Flex>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Chart Modal"
                style={{
                    content: {
                        width: '50%',
                        height: '50%',
                        margin: 'auto',
                        background: 'rgba(255, 255, 255, 0.9)',
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Agrega un fondo oscuro alrededor del modal
                    },
                }}
            >
                <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', padding: '5px', cursor: 'pointer' }}>
                    <XIcon className="h-6 w-6" /> {/* Ícono X para cerrar el modal */}
                </button>

                <div className="text-center mt-6">
                    <label className="text-xl font-semibold mb-4">Seleccionar Año</label>
                    <select
                        onChange={handleYearChange}
                        value={selectedYear}
                        defaultValue=""
                        className="w-38 p-2  ml-2 border rounded-md"
                    >
                        <option disabled value="">-- YEAR --</option>
                        {yearOptions}
                    </select>
                </div>

                {selectedYear && (
                    <AreaChart
                        data={yearlyAverages.find((yearData) => yearData[0].year === selectedYear)}
                        index="month"
                        categories={['average']}
                        colors={['emerald']}
                        valueFormatter={valueFormatter}
                        yAxisMin={0}
                        yAxisMax={1}
                        yAxisStep={0.1}
                    />
                )}
            </Modal>
        </div>
    );
};

export default GeneralStateChart;
