// i18n.js

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            //ingles
            en: {
                translation: {
                    // Home.js
                    assetsDataInsight: 'BUILDING DATA INSIGHT',
                    firstTileText: 'Nº of proyect',
                    SecondTileText: 'General',
                    ThirdTileText: 'Quantity of projects',
                    electricalText: 'Electrical',
                    edilicialText: 'Building',
                    structuralText: 'Structural ',
                    waterText: 'Sanitary',
                    PromGeneralState: 'Average ',
                    models: 'models',

                    // Header.js
                    logout: 'Logout',

                    //comparation
                    comparation: 'COMPARISON',
                    zones: 'ZONES',
                    names: 'Name',
                    variation: 'Variation',
                    state: 'Condition',
                    amount: 'Amount',

                    //Search.js
                    searchModel: 'Search for a model .. ',
                    search: 'Search',
                    //comparationTabModels
                    selectmodel1: ' Select model 1',
                    selectmodel2: ' Select model 2',

                    // EPEC-State Panel.js
                    FirstTitle: 'STATE',
                    Firstbuttom: 'BATHROOM',
                    Secondbuttom: 'KITCHEN',
                    Thirdbuttom: 'OFFICE',
                    Firstchart1: 'WALLS',
                    Firstchart2: 'HUMIDITY',
                    Firstchart3: 'CRACKS',
                    Firstchart4: 'NO PAINTED',
                    Secondchart1: 'AC',
                    Secondchart2: 'TOTAL',
                    Secondchart3: 'INACTIVES',
                    Thirdchart1: 'FP',
                    Thirdchart2: 'TOTAL',
                    Thirdchart3: 'REVIEW',
                    Fourthchart1: 'PARKING',
                    Fourthchart2: 'TOTAL',
                    Fivechart1:  'DOORS',
                    Sixchart1:  'LIGHT',
                    // EPEC-State Score.js
                    FirstHeader: 'GENERAL',
                    Secondheader: 'BUILDING',
                    Thirdheader: 'STRUCTURAL',
                    Fourthheader: 'ELECTRICAL',
                    Fivethheader: 'SANITARY',

                    //ModalCintoo.js
                    errorPointCloud:
                        'This model does not have an associated point cloud',

                    //home-model-div.js
                    Update: 'Update needed!',
                    pleaseUpdate:
                        'Please update the model for the current month.',
                },
            },

            //español
            es: {
                translation: {
                    // Home.js
                    assetsDataInsight: 'ANÁLISIS DE DATOS DE ACTIVOS',
                    firstTileText: ' Nº de proyectos',
                    SecondTileText: 'General',
                    ThirdTileText: 'Cantidad de proyectos',
                    electricalText: 'Electrico',
                    edilicialText: 'Edilicio',
                    structuralText: 'Estructural',
                    waterText: 'Sanitario',
                    PromGeneralState: 'Promedio ',
                    models: 'modelos',

                    // Header.js
                    logout: 'Logout',
                    //comparation
                    comparation: 'COMPARACION',
                    zones: 'ZONAS',
                    names: 'Nombre',
                    variation: 'Variacion',
                    state: 'Estado',
                    amount: 'Cantidad',

                    //Search.js
                    searchModel: 'Buscar un modelo...',
                    search: 'Buscar',

                    //comparationTabModels
                    selectmodel1: ' Seleccionar model 1',
                    selectmodel2: ' Seleccionar model 2',
                    // EPEC-State Panel.js
                    FirstTitle: 'ESTADO',
                    Firstbuttom: 'BAÑO',
                    Secondbuttom: 'COCINA',
                    Thirdbuttom: 'OFICINA',
                    Firstchart1: 'PAREDES',
                    Firstchart2: 'HUMEDAD',
                    Firstchart3: 'GRIETAS',
                    Firstchart4: 'SIN PINTAR',

                    Secondchart1: 'AC',
                    Secondchart2: 'TOTAL',
                    Secondchart3: 'INACTIVOS',
                    Thirdchart1: 'EXT.',
                    Thirdchart2: 'TOTAL',
                    Thirdchart3: 'REVISAR',
                    Fourthchart1: 'PARKING',
                    Fourthchart2: 'TOTAL',
                    Fivechart1:  'PUERTAS',
                    Sixchart1:  'LUCES',

                    // EPEC-State Score.js
                    FirstHeader: 'GENERAL',
                    Secondheader: 'EDILICIO',
                    Thirdheader: 'ESTRUCTURAL',
                    Fourthheader: 'ELECTRICO',
                    Fivethheader: 'SANITARIO',

                    //ModalCintoo.js
                    errorPointCloud:
                        'Este modelo no tiene una point cloud asociada',
                    //home-model-div.js
                    Update: '¡Actualización necesaria!',
                    pleaseUpdate:
                        'Por favor, actualiza el modelo para el mes actual.',
                },
            },

            // Agrega más idiomas según sea necesario
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
