import React from 'react';
import i18n from '../context/i18n'; // Asegúrate de importar la instancia correcta de i18n
import usaFlag from '../img/usa.png'; // Ajusta la ruta de la imagen según sea necesario
import spainFlag from '../img/spain.png'; // Ajusta la ruta de la imagen según sea necesario

const LanguageSelector = () => {

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={usaFlag}
        alt="English"
        onClick={() => changeLanguage('en')}
        style={{ cursor: 'pointer', width: '30px', marginRight: '10px' }}
      />

      <img
        src={spainFlag}
        alt="Español"
        onClick={() => changeLanguage('es')}
        style={{ cursor: 'pointer', width: '30px' }}
      />
      {/* Agrega más imágenes para otros idiomas si es necesario */}
    </div>
  );
};

export default LanguageSelector;
