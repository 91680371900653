import axios from 'axios'

async function getAccesstoken() {
    const token = localStorage.getItem('token'); // Obtener el token del almacenamiento local
    console.log(token)
    return axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/forge/token`, {
            headers: {
                'Authorization': token // Incluir el token en el encabezado de autorización
            }
        })
        .then((response) => {

            return response.data
            
        })
        .catch((err) => {
            console.log('Error en la solicitud:', err.response ? err.response.data : err); // Mostrará detalles sobre el error
        })
}
const Client = { getAccesstoken }
export default Client
