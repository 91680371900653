import React, { useEffect, useRef, useState } from 'react'
import { ClipLoader } from 'react-spinners'

const ModalCintoo = ({ isOpen, onClose, iframeSrc ,passCinto}) => {
    const modalRef = useRef(null)
    const iframeRef = useRef(null)
    const [isLoading, setIsLoading] = useState(true)
    const enterFullscreen = () => {
        if (iframeRef.current) {
            if (iframeRef.current.requestFullscreen) {
                iframeRef.current.requestFullscreen()
            } else if (iframeRef.current.webkitRequestFullscreen) {
                iframeRef.current.webkitRequestFullscreen()
            } else if (iframeRef.current.msRequestFullscreen) {
                iframeRef.current.msRequestFullscreen()
            }
        }
    }
    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose()
        }
    }

    const handleEscape = (e) => {
        if (e.key === 'Escape') {
            onClose()
        }
    }

    const handleIframeLoad = () => {
        setIsLoading(false)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleEscape)
        window.addEventListener('mousedown', handleOutsideClick)

        return () => {
            window.removeEventListener('keydown', handleEscape)
            window.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])
    return (
        <div
        className={`${
            isOpen ? 'block' : 'hidden'
        } fixed z-10 inset-0 overflow-hidden dark:bg-gray-900 flex items-center justify-center`}
    >
        <div
            className="w-[84vw] h-[80vh] bg-white dark:bg-gray-800 rounded-lg shadow-lg flex flex-col items-center justify-center"
            ref={modalRef}
        >
            {/* <p>Password : {passCinto}</p> */}
            <button onClick={enterFullscreen}>
                            {/* SVG para Fullscreen */}
                            <svg
                                width="40px"
                                height="40px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6 9.99739C6.01447 8.29083 6.10921 7.35004 6.72963 6.72963C7.35004 6.10921 8.29083 6.01447 9.99739 6"
                                    stroke="#1C274C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M6 14.0007C6.01447 15.7072 6.10921 16.648 6.72963 17.2684C7.35004 17.8888 8.29083 17.9836 9.99739 17.998"
                                    stroke="#1C274C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M17.9976 9.99739C17.9831 8.29083 17.8883 7.35004 17.2679 6.72963C16.6475 6.10921 15.7067 6.01447 14.0002 6"
                                    stroke="#1C274C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M17.9976 14.0007C17.9831 15.7072 17.8883 16.648 17.2679 17.2684C16.6475 17.8888 15.7067 17.9836 14.0002 17.998"
                                    stroke="#1C274C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    opacity="0.5"
                                    d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                    stroke="#1C274C"
                                    stroke-width="1.5"
                                />
                            </svg>
                        </button>


                        {isLoading && (
                            <div className="flex justify-center items-center  h-[70vh]  lg:w-[100vh] xl:w-[160vh">
                                <ClipLoader size={60} color={'#123abc'} />
                            </div>
                        )}

                        {iframeSrc && (
                            <div className="relative h-[70vh]  lg:w-[100vh] xl:w-[160vh] md:w-[70vh]">
                                <iframe
                                    ref={iframeRef}
                                    className="w-full h-full"
                                    src={iframeSrc}
                                    allowFullScreen
                                    onLoad={handleIframeLoad}
                                ></iframe>
                            </div>
                        )}
                    </div>
                </div>
       
    )
}

export default ModalCintoo
