import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './components/colorAdskExtension'
import './components/summaryExtension'
// import './components/GoogleMapsExtension.js'

import './App.css'
import { Header } from './components/Header'
import Footer from './components/Footer'
import { Login } from './pages/login'
import { RegisterUser } from './pages/register'
import { CreateModel } from './pages/create-model'
import { CreateCompanies } from './pages/create-companies'
import { Home } from './pages/home'
import { Comparation } from './pages/comparation'

import ViewerPage from './components/ViewerPage'
import Project from './pages/project'
import { ModelProvider } from './context/ModelContext'

function App() {
    return (
        <div className="App font-poppins bg-gray-100 dark:bg-gray-800 min-h-screen ">

            <Router>
                    <ModelProvider>
                        <Routes>
                            {/* Página de inicio de sesión sin encabezado ni pie de página */}
                            <Route path="/" element={<Login />} />

                            {/* Resto de las páginas con encabezado y pie de página */}
                            <Route
                                path="/*"
                                element={
                                    <>
                                        <Header />
                                        <Routes>
                                            {/* <Route path="/" element={<Roadmap />} /> */}
                                            <Route
                                                path="/home"
                                                element={<Home />}
                                            />
                                            <Route
                                                path="/register"
                                                element={<RegisterUser />}
                                            />
                                            <Route
                                                path="/create-model"
                                                element={<CreateModel />}
                                            />
                                                  <Route
                                                path="/create-companies"
                                                element={<CreateCompanies />}
                                            />
                                            <Route
                                                path="/viewer"
                                                element={<ViewerPage />}
                                            />
                                            <Route
                                                path="/project/:id"
                                                element={<Project />}
                                            />

                                            <Route
                                                path="/comparation"
                                                element={<Comparation />}
                                            />

                                        </Routes>
                                        <Footer />
                                    </>
                                }
                            />
                        </Routes>
                    </ModelProvider>
            </Router>
        </div>
    )
}

export default App
