// Componente Footer
const Footer = () => {
  return (
      <footer className="w-full ">
          <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
              <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 ">
                  © 2023 <a href="https://www.voyansi.com/" className="hover:underline text-[#493E98]">Voyansi™</a>. All Rights Reserved.
              </span>
              <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                  <li>
                      <a href="https://www.voyansi.com/about-voyansi" className="mr-4 hover:underline md:mr-6">About</a>
                  </li>
                  <li>
                      <a href="https://www.voyansi.com/privacy-policy" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
                  </li>
                  <li>
  <a href="mailto:sales@voyansi.com" className="hover:underline">Contact</a>
</li>

              </ul>
          </div>
      </footer>
  )
}

export default Footer;
