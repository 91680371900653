const { Autodesk } = window

class BaseExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options)
        this._onObjectTreeCreated = (ev) => this.onModelLoaded(ev.model)
        this._onSelectionChanged = (ev) =>
            this.onSelectionChanged(ev.model, ev.dbIdArray)
        this._onIsolationChanged = (ev) =>
            this.onIsolationChanged(ev.model, ev.nodeIdArray)
    }

    load() {
        this.viewer.addEventListener(
            Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
            this._onObjectTreeCreated
        )
        this.viewer.addEventListener(
            Autodesk.Viewing.SELECTION_CHANGED_EVENT,
            this._onSelectionChanged
        )
        this.viewer.addEventListener(
            Autodesk.Viewing.ISOLATE_EVENT,
            this._onIsolationChanged
        )
        return true
    }

    unload() {
        this.viewer.removeEventListener(
            Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
            this._onObjectTreeCreated
        )
        this.viewer.removeEventListener(
            Autodesk.Viewing.SELECTION_CHANGED_EVENT,
            this._onSelectionChanged
        )
        this.viewer.removeEventListener(
            Autodesk.Viewing.ISOLATE_EVENT,
            this._onIsolationChanged
        )
        return true
    }

    onToolbarCreated() {}

    onModelLoaded(model) {}

    onSelectionChanged(model, dbids) {}

    onIsolationChanged(model, dbids) {}

    findLeafNodes(model) {
        return new Promise(function (resolve, reject) {
            model.getObjectTree(function (tree) {
                let leaves = []
                tree.enumNodeChildren(
                    tree.getRootId(),
                    function (dbid) {
                        if (tree.getChildCount(dbid) === 0) {
                            leaves.push(dbid)
                        }
                    },
                    true
                )
                resolve(leaves)
            }, reject)
        })
    }

    async findPropertyNames(model) {

        const dbids = await this.findLeafNodes(model)
        return new Promise(function (resolve, reject) {
            model.getBulkProperties(
                dbids,
                {},
                function (results) {
                    let propNames = new Set()
                    for (const result of results) {
                        for (const prop of result.properties) {
                            propNames.add(prop.displayName)
                        }
                    }
                    resolve(Array.from(propNames.values()))
                },
                reject
            )
        })
    }

    createToolbarButton(buttonId, buttonIconUrl, buttonTooltip) {
        let group = this.viewer.toolbar.getControl('dashboard-toolbar-group')
        if (!group) {
            group = new Autodesk.Viewing.UI.ControlGroup(
                'dashboard-toolbar-group'
            )
            this.viewer.toolbar.addControl(group)
        }
        const button = new Autodesk.Viewing.UI.Button(buttonId)
        button.setToolTip(buttonTooltip)
        group.addControl(button)
        const icon = button.container.querySelector('.adsk-button-icon')
        if (icon) {
            icon.style.backgroundImage = `url(${buttonIconUrl})`
            icon.style.backgroundSize = `24px`
            icon.style.backgroundRepeat = `no-repeat`
            icon.style.backgroundPosition = `center`
        }
        return button
    }

    removeToolbarButton(button) {
        const group = this.viewer.toolbar.getControl('dashboard-toolbar-group')
        group.removeControl(button)
    }
}

class SummaryPanel extends Autodesk.Viewing.UI.PropertyPanel {
    constructor(extension, id, title) {
        super(extension.viewer.container, id, title)
        this.extension = extension
    }

    async update(model, dbids, propNames) {
        this.removeAllProperties()
        for (const propName of propNames) {
            const initialValue = {
                sum: 0,
                count: 0,
                min: Infinity,
                max: -Infinity,
            }
            const aggregateFunc = (aggregate, value, property) => {
                return {
                    count: aggregate.count + 1,
                    sum: aggregate.sum + value,
                    min: Math.min(aggregate.min, value),
                    max: Math.max(aggregate.max, value),
                    units: property.units,
                    precision: property.precision,
                }
            }
            const { sum, count, min, max, units, precision } =
                await this.aggregatePropertyValues(
                    model,
                    dbids,
                    propName,
                    aggregateFunc,
                    initialValue
                )
            if (count > 0) {
                const category = propName
                this.addProperty('Count', count, category)
                this.addProperty(
                    'Sum',
                    this.toDisplayUnits(sum, units, precision),
                    category
                )
                this.addProperty(
                    'Avg',
                    this.toDisplayUnits(sum / count, units, precision),
                    category
                )
                this.addProperty(
                    'Min',
                    this.toDisplayUnits(min, units, precision),
                    category
                )
                this.addProperty(
                    'Max',
                    this.toDisplayUnits(max, units, precision),
                    category
                )
            }
        }
    }

    async aggregatePropertyValues(
        model,
        dbids,
        propertyName,
        aggregateFunc,
        initialValue = 0
    ) {
        return new Promise(function (resolve, reject) {
            let aggregatedValue = initialValue
            model.getBulkProperties(
                dbids,
                { propFilter: [propertyName] },
                function (results) {
                    for (const result of results) {
                        if (result.properties.length > 0) {
                            const prop = result.properties[0]
                            aggregatedValue = aggregateFunc(
                                aggregatedValue,
                                prop.displayValue,
                                prop
                            )
                        }
                    }
                    resolve(aggregatedValue)
                },
                reject
            )
        })
    }

    toDisplayUnits(value, units, precision) {
        return Autodesk.Viewing.Private.formatValueWithUnits(
            value,
            units,
            3,
            precision
        )
    }
}
const SUMMARY_PROPS = ['Length', 'Area', 'Volume', 'Density', 'Mass', 'Price']

class SummaryExtension extends BaseExtension {
    constructor(viewer, options) {
        super(viewer, options)
        this._button = null
        this._panel = null
    }

    load() {
        super.load()
        console.log('SummaryExtension loaded.')
        return true
    }

    unload() {
        super.unload()
        if (this._button) {
            this.removeToolbarButton(this._button)
            this._button = null
        }
        if (this._panel) {
            this._panel.setVisible(false)
            this._panel.uninitialize()
            this._panel = null
        }
        console.log('SummaryExtension unloaded.')
        return true
    }

    onToolbarCreated() {
        this._panel = new SummaryPanel(
            this,
            'model-summary-panel',
            'Model Summary'
        )
        this._button = this.createToolbarButton(
            'summary-button',
            'https://img.icons8.com/small/32/brief.png',
            'Show Model Summary'
        )
        this._button.onClick = () => {
            this._panel.setVisible(!this._panel.isVisible())
            this._button.setState(
                this._panel.isVisible()
                    ? Autodesk.Viewing.UI.Button.State.ACTIVE
                    : Autodesk.Viewing.UI.Button.State.INACTIVE
            )
            if (this._panel.isVisible()) {
                this.update()
            }
        }
    }

    onModelLoaded(model) {
        super.onModelLoaded(model)
        this.update()
    }

    onSelectionChanged(model, dbids) {
        super.onSelectionChanged(model, dbids)
        this.update()
    }

    onIsolationChanged(model, dbids) {
        super.onIsolationChanged(model, dbids)
        this.update()
    }

    async update() {
        if (this._panel) {
            const selectedIds = this.viewer.getSelection()
            const isolatedIds = this.viewer.getIsolatedNodes()
            if (selectedIds.length > 0) {
                // If any nodes are selected, compute the aggregates for them
                this._panel.update(
                    this.viewer.model,
                    selectedIds,
                    SUMMARY_PROPS
                )
            } else if (isolatedIds.length > 0) {
                // Or, if any nodes are isolated, compute the aggregates for those
                this._panel.update(
                    this.viewer.model,
                    isolatedIds,
                    SUMMARY_PROPS
                )
            } else {
                // Otherwise compute the aggregates for all nodes
                const dbids = await this.findLeafNodes(this.viewer.model)
                this._panel.update(this.viewer.model, dbids, SUMMARY_PROPS)
            }
        }
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
    'SummaryExtension',
    SummaryExtension
)
