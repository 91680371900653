import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { AreaChart, Card, Flex, Metric, Badge } from '@tremor/react'
import { ClockIcon } from '@heroicons/react/outline'
const ProjectCount = ({ numProjects, companyID }) => {
    const { t } = useTranslation()
    const [modelsByMonth, setModelsByMonth] = useState(null)
    const [cookies, _] = useCookies(['access_token'])
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1 // Sumar 1 porque enero es 0

    useEffect(() => {
        if (numProjects != 0) {
            async function fetchModelsByMonth() {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}/companies/getNumberModels/${companyID}`,
                        { numberModels: numProjects },
                        {
                            headers: { authorization: cookies.access_token },
                        }
                    )

                    if (response.data && response.data.modelsByMonth) {
                        setModelsByMonth(response.data.modelsByMonth)
                    }
                } catch (error) {
                    console.error('Error al obtener modelsByMonth:', error)
                }
            }

            fetchModelsByMonth()
        }
    }, [numProjects])

    const chartData = []

    if (modelsByMonth) {
        for (const year in modelsByMonth) {
            for (let month = 1; month <= 12; month++) {
                const numModels = modelsByMonth[year][month]|| 0
                console.log(numModels)
                chartData.push({
                    year: parseInt(year),
                    month,
                    'Nº of project': numModels,
                })
            }
        }
    }

    const valueFormatter = (number) =>
        new Intl.NumberFormat('us').format(number)

    return (
        <div className="rounded-xl bg-gradient-to-r p-1 from-[#6EE7B7] via-[#3B82F6] to-[#9333EA] w-full m-1 shadow-2xl	dark:shadow-zinc-700 ">
            <Card className="w-full ">
                <div className="flex justify-between items-center">
                    <div>
                        <Metric>
                            {numProjects} {t('models')}
                        </Metric>
                    </div>
                    <div>
                        <Badge size="xs" icon={ClockIcon} color="violet">
                            ({currentYear}:{currentMonth})
                        </Badge>
                    </div>
                </div>

                <AreaChart
                    data={chartData}
                    index="month"
                    categories={['Nº of project']}
                    colors={['green']}
                    className="h-28"
                    valueFormatter={valueFormatter}
                    yAxisWidth={14}
                />
            </Card>
        </div>
    )
}

export default ProjectCount
